.sdCard{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 2.5rem 1rem;
    border-radius: 10px;
    background: #fff;
    min-width: 220px;
    display: inline-flex;
    flex-direction: column;
    gap: .2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 2rem;
}

.sdcExtra{
    position: absolute;
    top: -17px;
    right: 25px;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.sdceIcon{
    width: 35px;
    height: 35px;
    line-height: 40px;
    text-align: center;
    background: #056ffa;
    color: #f4f9ff;
    font-size: 1.4rem;
    border-radius: 50%;
    cursor: pointer;
}

.sdceIconDown{
    background: rgb(255, 191, 0);
}

.sdceIconDel{
    background: rgb(248, 76, 46);
}

.sdcText{
    font-size: 1.4rem;
    font-weight: 600;
}

.sdcSubText{
    font-size: 1.2rem;
}