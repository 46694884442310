.container {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  margin: 0 auto;
  gap: 1rem;
  padding: 0rem 0 2rem;
}

@media (max-width: 700px) {
  .container{
    flex-direction: column;
  }
}

