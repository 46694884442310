.ptCard{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
}

.ptCardIcon{
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ddd;
    border-radius: 50%;
    font-size: 2.2rem;
}

.ptCardTitle{
    font-size: 1.32rem;
}

.ptCardValue{
    font-size: 2rem;
    font-weight: 700;
}