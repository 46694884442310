.displaySidebar{
    visibility: hidden;
}

.sidebar {
    width: 250px;
    padding: 0rem 2rem;
    position: fixed;
    /* height: 100vh; */
    background-color: #fff;
    height: calc(100% - 70px);
    overflow: scroll;
}

.sidebar::-webkit-scrollbar{
    width: 5px;
}

.sidebar::-webkit-scrollbar-thumb{
    background: #ddd;
}

.sidebarList{
    padding-top: 8rem;
}
.sidebarItem {
    margin-top: 1rem;
    padding: 1rem 2rem;
    font-weight: 500;
    list-style-type: none;
    font-size: 1.6rem;
    border-radius: 5px;
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    
}
.active{
    background-color: #056ffa;
    color: #fff;

}
.active:hover{
    background-color: #056ffa;
    color: #fff;

}
.sidebarItem:hover {
    background-color: #056ffa20;

}

.line{
    border: none;
    height: 1px;
    background-color: #f5f5f5;
    margin: 2rem 0rem 2rem

}

@media screen and (max-width: 768px) {
    .sidebar {


    }

    
}