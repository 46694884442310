.textarea {
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    border: 1px solid #eee;
    width: 100%;
    padding: 1rem 1rem;
    margin-top: 0.5rem;
    border-radius: 5px;
    min-height: 10rem;
    min-width: 20rem;
    resize: none;
}

.textarea::-webkit-scrollbar{
    width: 0;
}

.textarea::placeholder{
    font-size: 1.4rem;
}

.label {
    font-size: 1.6rem;
    font-weight: 500;
    color: #5f5f5f;
}