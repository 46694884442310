.footer-container{
    width: 100%;
    height: 50rem;
    padding: 40px 0px 0px 0px;
    background-color: #FFFFFF;
    border-top: 1px solid #ddd;
    /* box-shadow:  0 -5px 14px -4px rgb(5 111 250 / 10%);  */
}

/* upper box styling  */
.upperBox{
    display: flex;
    flex-direction: row;
    height: 70%;
    margin: 0px 9rem 40px;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.upperBox-left{
    height: 100%;
    width: 30%;
}
.upperBox-left>h1{
    margin: 0px;
    font-size: 4rem;
}
.upperBox-right{
    color: #58616A;
    width: 70%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.upperBox-right-boxes-list{
    margin: 0px;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
}
.upperBox-right-boxes-list-items h3{
    margin : 0px;
    margin-bottom: 20px ;
    font-size: 2.3rem;
    opacity: 0.7;
}
.upperBox-right-boxes-list-items {
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 1.7rem;
    opacity: 0.8;
    color: black;
    cursor: pointer;

}
.upperBox-right-boxes-list-items:hover{
    color: #056ffa;
}






/* Lower-box css styling  */
.lowerBox{
    box-sizing: border-box;
    height: 70px;
    background-color: #F6F8FA;
}

.lowerBox-field{
    height: inherit;
    box-sizing: border-box;
    margin: 0px 80px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.lowerBox-list-item{
    list-style: none;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}


.lowerBox-list-item-content{
    color: #58616A;
}
.lowerBox-list-item-images{
    align-items: center;

}

/* .lowerBox-list-item-images:hover{
    background-color: #056ffa;
} */

.footer-brand-logo{
    height: 20px;
    width: 20px;
    color : black
}

.footer-brand-logo:hover{
    color: #056ffa;
}





/* media query */

@media screen and (max-width : 750px) {
    .footer-container{
        height: 60rem;
        padding: 40px 0px 0px 0px;
        background-color: #FFFFFF;
    }

    /* upper box styling  */
    .upperBox{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 70%;
        margin: 0px 4rem 40px;
    }

    .upperBox-left{
        height: 30%;
        width: 100%;
    }
    .upperBox-left>h1{
        margin: 0px;
        font-size: 4rem;
    }
    .upperBox-right{
        color: #58616A;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .upperBox-right-boxes-list{
        margin: 0px;
        height: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
    }
    .upperBox-right-boxes-list-items h3{
        margin : 0px;
        margin-bottom: 2rem ;
        font-size: 2.3rem;
        opacity: 0.7;
    }
    .upperBox-right-boxes-list-items {
        margin-bottom: 1rem;
        font-size: 1.7rem;

    }


    /* Lower-box css styling  */
    .lowerBox{
        height: 20%;
        background-color: #F6F8FA;
    }

    .lowerBox-field{
        height: 100%;
        margin: 0px 8rem;
        padding: 2rem;
    }

    .lowerBox-list-item{
        font-size: 12px;
        gap: 1.5rem;
    }
    .footer-brand-logo{
        color: black;
    }
    
}
@media screen and (max-width : 750px) {
    .upperBox{
        margin: 0px 0rem 40px 4rem;
    }

}