.blogType1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.bT1Img {
    width: 100%;
}

.bT1Img img {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.bT1Text{
    display: flex;
    flex-direction: column;
    gap: .6rem;
}

.bT1TTop{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.dot{
    height: 6px;
    width: 6px;
    background: #777;
    border-radius: 50%;
}

.bT1TTDiv{
    font-weight: 600;
    font-size: 1.4rem;
    color: #777;
}

.bT1THeading{
    font-size: 2rem;
    font-weight: 600;
}

.bT1TBottom{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.bT1TBLC{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.8rem;
    gap: .6rem;
}