.container {
    width: 100%;
    max-width: 1440px;
    margin: auto;
}

.sectionContainer {
    margin-top: 6rem;
    padding-left: 12rem;
    padding-right: 12rem;


}

.section1 {
    width: 100%;
    display: flex;
    padding-bottom: 4rem;
    margin-top: 4rem;
}

.section1Left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 10rem; */

}

.section1Left>h1 {
    font-size: 4.2rem;
    line-height: 4.4rem;
    color: #000;
    font-weight: 900;

}

.section1Left>p {
    padding-top: 2rem;
    font-size: 1.8rem;
    line-height: 2rem;
    color: #000;
    font-weight: 200;


}

.section1Right {
    width: 50%;

}

.section1Right>img {
    width: 100%;
}



/* Traction section */
.tractionSection {
    padding-left: 20rem;
    padding-right: 20rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 2rem;
}

.tractionBox {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.homeTab {
    padding: 6rem 0 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.homeTabLeft {
    width: 50%;
}

.homeTabLeft img {
    width: 100%;
}

.homeTabRight {
    width: 40%;
}

.homeTabRight h1 {
    font-size: 4rem;
    font-weight: 800;
    line-height: 4.2rem;
}

.homeTabRight p {
    margin-top: 1rem;
    font-size: 1.8rem;
    font-weight: 400;
    margin: 1.6rem 0;
}

.homeTabRight img {
    width: 200px;
    margin: 1rem 0;
}

.homeTabPs {
    display: flex;
    gap: .5rem;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border: 1px solid #222;
    border-radius: 10px;
    margin: 1rem 0;
}

.homeTabPs img {
    width: 32px;
}

.homeTabPs p {
    font-size: 1.1rem;
    font-weight: 400;
}

.homeTabPs .htps2 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2rem;
}

.cardsSkeletonWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    align-items: center;
    justify-content: space-around;
}

@media (max-width: 768px) {
    .homeTab {
        flex-direction: column-reverse;
        align-items: flex-start;
        width: 100%;
    }

    .homeTabLeft {
        width: 90%;
    }

    .homeTabRight {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 0 4rem;
    }

    .homeTabRight h1 {
        font-size: 3.2rem;
        line-height: 3.8rem;
    }

    .homeTabRight p {
        font-size: 1.4rem;
    }
}

.sectionStartupCardContainer {}

.sectionStartupCardContainer>h1 {
    font-size: 4rem;
    font-weight: 800;
    line-height: 4.2rem;

}

.sectionStartupCardContainer>p {
    margin-top: 1rem;
    font-size: 1.8rem;
    font-weight: 300;

}

.startupGrid {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.exploreStartupButton {
    text-align: center;
}


/* Investing process section */
.investingProcessSection {
    margin-top: 4rem;
    padding: 6rem 16rem;
}

.investingProcessSection>h1 {
    text-align: center;
    font-size: 5rem;
}

.investingProcessSection>p {
    font-size: 1.8rem;
    width: 90%;
    text-align: center;
    margin: auto;
    color: #5f5f5f;

}

.investingProcessSection>a {
    font-size: 1.8rem;
    width: 100%;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #056ffa;
}



/* Asset class Classification */
.sectionAssetClassClassification {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background: rgb(3, 12, 34);
    background: linear-gradient(157deg, rgba(3, 12, 34, 1) 0%, rgba(11, 102, 195, 1) 44%, rgba(5, 111, 250, 0.8141850490196079) 76%, rgba(244, 249, 255, 1) 100%);
    /* background: #030c22;
    background: linear-gradient(149deg, rgba(3,12,34,1) 0%, rgba(4,57,131,1) 37%, rgba(5,111,250,1) 100%); */

}

.sectionAssetClassClassification h1 {
    font-size: 4rem;
    color: #fff;
}

.sectionAssetClassBox {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    row-gap: 2rem;
    flex-wrap: wrap;
    width: 100%;


}

.assetBox {
    position: relative;
    width: 30%;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;

}

.riskReturnSpan {
    position: absolute;
    right: 2rem;
    top: 2rem;
    padding: 0.5rem;
    font-size: 0.8rem;
    background-color: rgb(246, 190, 181);
    border-radius: 5px;
}

.assetHeadingBox {
    display: flex;
    align-items: center;
    gap: 10%;
}

.assetHeadingBox img {
    height: 4rem;
    width: 4rem;
}

.assetHeadingBox h1 {
    font-size: 2.4rem;
    font-weight: 600;
    color: #808080;
}

.parameters {
    margin-top: 2rem;
}

.parameter {
    display: flex;
    align-items: center;
    gap: 5%;
}

.parameter h2 {
    width: 30%;
    text-align: right;
    font-weight: 500;
    font-size: 1.4rem;

}

.progress {
    width: 100%;
    height: 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    overflow: hidden;
}

.progressBar {
    height: 100%;
    background: rgb(3, 12, 34);
    background: linear-gradient(149deg, rgba(3, 12, 34, 1) 0%, rgba(4, 57, 131, 1) 37%, rgba(5, 111, 250, 1) 100%);
    transition: width 0.3s ease-in-out;
}




/* Media queries */


@media screen and (max-width:1100px) {
    .sectionContainer {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .section1Left h1 {
        font-size: 3.6rem;
        line-height: 3.8rem;
    }

    .section1Left p {
        font-size: 1.6rem;
    }

    .tractionSection {
        padding-left: 10rem;
        padding-right: 10rem;
    }

    .investingProcessSection {
        padding: 6rem 6rem;
    }
}

@media screen and (max-width:1000px) {
    .assetBox {
        width: 45%;
    }
}


/* Media queries for tab screen */
@media screen and (max-width:768px) {
    .section1 {
        flex-direction: column;
        gap: 2rem;
    }

    .section1Left {
        width: 100%;
        justify-content: center;
    }

    .section1Left h1 {
        font-size: 4.2rem;
        line-height: 4.4rem;
    }

    .section1Left p {
        font-size: 1.8rem;
    }

    .section1Right {
        width: 100%;

    }

    .tractionSection {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .investingProcessSection>h1 {
        font-size: 4.2rem;
    }

    .investingProcessSection>p {
        font-size: 1.6rem;
        width: 100%;
    }
}



@media screen and (max-width:600px) {
    .sectionContainer {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .section1Left>h1 {
        font-size: 3.2rem;
        line-height: 3.4rem;
    }

    .section1Left>p {
        font-size: 1.6rem;
    }

    .section1Right {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .tractionSection {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .sectionStartupCardContainer>h1 {
        font-size: 3.2rem;
        line-height: 3.4rem;
    }

    .sectionStartupCardContainer>p {
        font-size: 1.4rem;

    }

    .investingProcessSection {
        padding: 6rem 3rem;
    }

    .investingProcessSection>h1 {
        font-size: 3.2rem;
    }

    .investingProcessSection>p {
        font-size: 1.4rem;
        line-height: 1.6rem;
        width: 100%;
    }
}