.joinedStartupCard {
    width: 300px;
    /* height: 180px; */
    border-radius: 14px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #c8c8c878;

    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    /* border: 1px solid #222; */
}

.jscTop{
    position: relative;
    width: 100%;
    height: 100px;
    /* border: 1px solid #222; */
    border-bottom: 1px solid #c8c8c878;
    display: flex;
    align-items: center;
    justify-content: center;

}

.jscTop img.siBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.siLogo{
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 14px;
    border: 1px solid #222;
    z-index: 2;
}

.siLogo img{
    width: 100%;
    height: 100%;
}

.jscBottom{
    padding: 2rem;
}

.jscbComName{
    font-size: 1.72rem;
    font-weight: bold;
}

@media (max-width: 480px) {
    .joinedStartupCard {
        width: 100%;
    }
}