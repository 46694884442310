.container {
  display: flex;
  width: 100%;
  margin: 0.3rem auto;
  padding: 1rem 0;
  border-radius: 8px;
  justify-content: space-between;
}

.containerWrap{
  display: flex;
  gap: 1rem;
}

.containerWrap img{
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;
}

.containerComment{
  display: flex;
  flex-direction: column;
  gap: .2rem;
}

.comment{
  font-size: 1.4rem;
  font-weight: 500;
}

.commentExtra{
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  color: #777;
  /* font-size: 1.2rem; */
}

.delCom{
  background: rgb(255, 83, 52);
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 700px) {
  .container{
    flex-direction: column;
    gap: 1rem;
  }

  .commentExtra{
    font-size: 1rem;
  }
}
