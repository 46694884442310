.overviewContainer{
    margin-top: 4rem;
}
.overviewContainer>h1{
    font-size: 2.4rem;
    font-weight: 500;
    color: #303030;

}
.overviewBox{
    margin-top: 4rem;
}
.headingAndNavigate{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0rem;
    align-items: center;
    
}
.headingAndNavigate>h1{
    font-size: 1.8rem;
    font-weight: 600;
    color: #303030;


}
.headingAndNavigate>h2{
    font-size: 1.4rem;
    font-weight: 500;
    color: #9E9E9E;
    cursor: pointer;

}

.overviewText{
    font-size: 1.6rem;
    color: #303030;
    font-weight: 400;

}
.overviewIncubateeStartupsBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1rem;

}
.overviewIncubateeStartup{
    width: 32%;
}
.overviewTeamBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1rem;

}
.overviewTeamBox>div{
    width: 49%;
}



@media screen and (max-width : 768px) {
    .overviewTeamBox>div{
        width: 100%;
    }
    .overviewIncubateeStartup{
        width: 49%;
    }
}
@media screen and (max-width : 425px) {
    .overviewIncubateeStartup{
        width: 100%;
    }
}