.footer {
    background: #eeeeee;
    padding: 4rem 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.footerTop {
    background: #222;
    border-radius: 10px;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
}

.ftLeft {
    font-size: 3.4rem;
    font-weight: 600;
}

.ftRight {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.fmbSText {
    font-size: 1.4rem;
}

.inputBox {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.4rem;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid #eee;
    border-radius: 10px;
    padding: .2rem 1rem;
}

.inputBox input {
    border: none;
    outline: none;
    padding: 1rem 0;
    background: none;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.footerMiddle {
    display: flex;
    padding: 2rem;
}

.fmBoxOuter {
    width: 50%;
    display: flex;
}

.fmBoxAnother {
    width: 50%;
}

.fmBox {
    width: 25%;
    /* padding: 0 2rem; */
}

.fmBox img {
    width: 180px;
}

.fmbHeading {
    font-size: 2rem;
    font-weight: 700;
    color: #222;
    padding-bottom: 1rem;
}

.fmbLinks {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.fmblTextA {
    display: flex;
    gap: .6rem;
    align-items: center;
    font-size: 1.6rem;
    cursor: pointer;
}

.fmblText {
    display: flex;
    gap: .6rem;
    align-items: center;
    font-size: 1.6rem;
}

.fmbSocial {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem 0;
}

.fmbSocial a {
    text-decoration: none;
    color: inherit;
}

.fmbsIcon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #222;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
}

.footerBottom {
    text-align: center;
    font-size: 1.4rem;
    border-top: 1px solid #ddd;
    padding-top: 2rem;
}

@media (max-width: 768px) {
    .footer{
        padding: 4rem 0rem 2rem;
    }

    .footerMiddle {
        flex-direction: column;
        gap: 2rem;
    }

    .fmBoxOuter {
        width: 100%;
        display: flex;
    }

    .fmBox {
        width: 100%;
        padding: 0 0rem;
    }

    .footerBottom {
        padding: 2rem 2rem 0;
    }
}