.ql-editor {
  width: 100%;
  font-size: 14px;
  padding: 0;
}

.ql-editor img {
  max-width: 100%;
  min-width: 100%;
  height: auto;
  /* max-height: 450px; */
  display: block;
  margin: 1rem 0;
  /* margin: 1rem auto; */
  border-radius: 12px;
}

.ql-editor p {
  font-size: 16px;
}

blockquote {
  font-size: 14px;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-right {
  text-align: right;
}

.ql-align-left {
  text-align: left;
}

.ql-bold {
  font-weight: bold;
}

.ql-italic {
  font-style: italic;
}

.ql-underline {
  text-decoration: underline;
}

.ql-strike {
  text-decoration: line-through;
}

.ql-color-red {
  color: red;
}

.ql-color-green {
  color: green;
}

.ql-color-blue {
  color: blue;
}

.ql-background-red {
  background-color: red;
}

.ql-background-green {
  background-color: green;
}

.ql-background-blue {
  background-color: blue;
}

.ql-indent-1 {
  padding-left: 1em;
}

.ql-indent-2 {
  padding-left: 2em;
}

.ql-indent-3 {
  padding-left: 3em;
}

.ql-indent-4 {
  padding-left: 4em;
}

.ql-indent-5 {
  padding-left: 5em;
}

.ql-indent-6 {
  padding-left: 6em;
}

.ql-indent-7 {
  padding-left: 7em;
}

.ql-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ql-list .ql-indent-1 {
  padding-left: 1.5em;
}

.ql-list .ql-indent-2 {
  padding-left: 3em;
}

.ql-list .ql-indent-3 {
  padding-left: 4.5em;
}

.ql-list .ql-indent-4 {
  padding-left: 6em;
}

.ql-list .ql-indent-5 {
  padding-left: 7.5em;
}

.ql-list .ql-indent-6 {
  padding-left: 9em;
}

.ql-list .ql-indent-7 {
  padding-left: 10.5em;
}

.ql-list.ql-indent-1 {
  padding-left: 3em;
}

.ql-list.ql-indent-2 {
  padding-left: 4.5em;
}

.ql-list.ql-indent-3 {
  padding-left: 6em;
}

.ql-list.ql-indent-4 {
  padding-left: 7.5em;
}

.ql-list.ql-indent-5 {
  padding-left: 9em;
}

.ql-list.ql-indent-6 {
  padding-left: 10.5em;
}

.ql-list.ql-indent-7 {
  padding-left: 12em;
}

.ql-image {
  max-width: 100%;
  height: auto;
}
