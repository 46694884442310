.teamCardContainer{
    border: 1px solid #E0E0E0;
    border-bottom: 3px solid #E0E0E0;
    border-radius: 5px;
    padding: 1.5rem;
    min-width: 250px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

}
.teamCardContainer:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;;
}
.teamCardBox{
    display: flex;
    flex-direction: column;
}
.teamCardBox>h1{
    font-size: 1.6rem;
    font-weight: 500;
    color: #303030;

    
}
.teamCardBox>h2{
    font-size: 1.4rem;
    font-weight: 500;
    color: #9E9E9E;

}
.teamCardContainer>img{
    width: 8rem;
    height: 8rem;
    border-radius: 100%;
}

