.button-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem 0 0;
}

.button{
    width: 12rem;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #1e90ff;
    color: white;
    font-weight: bold;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    transition: 0.3s;
}
