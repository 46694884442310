.container {
    max-width: 1440px;
    margin: auto;
    width: 100%;
    min-height: 100vh;
}

.pdfContainer {
    min-width: 900px;
    width: 70%;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    min-height: 100vh;
    position: relative;
    padding: 6rem;
}

.zappInfo {
    position: absolute;
    right: 2rem;
    top: 2rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;

}

.zappInfo img {
    height: 2rem;
}

.logoAndName {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.logoAndName img {
    height: 4rem;
}

.logoAndName h2 {
    font-size: 2.4rem;
}

.description {
    font-size: 1.8rem;
    font-weight: 300;
    margin-top: 1.5rem;
}

.sectors {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.fieldGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
}

.field {
    width: 49%;
}


/* Team member */
.teamSection {
    margin-top: 3rem;
}

.teamMembers {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;

}

.member {
    padding: 2rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid #eee;


}

.member img {
    border-radius: 100%;
    width: 6rem;
    height: 6rem;
}

.memberDetail h4 {
    font-size: 1.4rem;
    font-weight: 600;
}

.memberDetail p {
    font-size: 1.2rem;
    font-weight: 300;
}

.contactUs {
    margin-top: 3rem;
}

.contactInfo {
    display: flex;
    gap: 5rem;
    font-size: 1.6rem;
}

.info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    font-size: 1.4rem;
}

.table th,
.table td {
    border: 1px solid #ccc;
    text-align: center;
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.table input {
    width: 100%;
    border: none;
    padding: 1rem;
    text-align: center;
}

/* common css */
.titleHeading {
    font-size: 1.6rem;
    font-weight: 700;
}