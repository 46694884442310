.chip {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
}

.chipRemove {
    margin-left: 5px;
    background: none;
    border: none;
    font-size: inherit;
    cursor: pointer;
}