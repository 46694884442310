.blogCard{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    min-width: 280px;
    max-width: 30%;
}

.blogCard img{
    width: 100%;
    height: 180px;
    border-radius: 10px;
}

.blogCardText{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.2rem;
}

.bctCat{
    border-radius: 100px;
    padding: .4rem .6rem;
    background: #F4F9FF;
    color: #056ffa;
    text-align: center;
}

.bctTitle{
    font-size: 1.4rem;
    font-weight: 600;
}

.bctPara{
    color: #777;
}

.bctBtn{
    border-radius: 100px;
    padding: .8rem .1rem;
    background: #056ffa;
    color: #fff;
    text-align: center;
    width: 132px;
    cursor: pointer;
    text-decoration: none;
}

.bctBtn:hover{
    background: #4393fb;
}

.blogCard a{
    text-decoration: none;
}