.stCard{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 2rem;
    border-radius: 10px;
    background: #fff;
    width: 210px;
    height: 250px;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 2rem;
}

.stcExtra{
    position: absolute;
    top: -17px;
    right: 25px;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.stceIcon{
    width: 35px;
    height: 35px;
    line-height: 40px;
    text-align: center;
    background: #056ffa;
    color: #f4f9ff;
    font-size: 1.4rem;
    border-radius: 50%;
    cursor: pointer;
}

.stceIconDel{
    background: rgb(248, 76, 46);
}

.stcImg{
    min-width: 100px;
    height: 100px;
    border: 4px solid #056ffa;
    padding: .32rem;
    background: #fff;
    overflow: hidden;
    border-radius: 50%;
}

.stcImg img{
    width: 100%;
    height: 100%;
    border-radius: 50%;

}

.stcText{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
}

.stctName{
    font-size: 1.8rem;
    font-weight: 700;
}

.stctDes{
    font-size: 1.2rem;
    font-weight: 600;
    color: #777777;
    text-transform: uppercase;
}

.stcText a{
    text-decoration: none;
    color: #222;
    font-size: 1.8rem;
}