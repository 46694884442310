.container {
    width: 100%;
    margin: 4rem 0rem;
}

.heading {
    font-size: 40px;
    font-weight: 600;
    color: #056ffa;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heading::after {
    content: "";
    width: 450px;
    height: 4px;
    border-radius: 1px;
    bottom: -20px;
    background-color: #056ffa;
    position: absolute;
}

.form_container {
    margin: 4rem 10rem;
}

.isRegisteredOnMCA {
    margin: 10px 0px;
}

.right {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.box {
    margin: 1rem 0rem;
}

.fileField {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.fileField .styleFile {
    position: absolute;
    top: 3.5rem;
    background: #fff;
    width: 98%;
    left: 1%;
    overflow: hidden;
    height: 30px;
    line-height: 30px;
    font-size: 1.4rem;
    font-weight: 400;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
}

.fileField label {
    font-size: 1.6rem;
    font-weight: 500;
    color: #5f5f5f;
}

.fileField input {
    border: 1px solid #eee;
    padding: 1rem;
    border-radius: 4px;
    font-size: 1.6rem;
}

.from_heading {
    font-size: 25px;
    font-weight: 400;
    color: #2c444e;
    margin-bottom: 20px;
}

.inputLabel {
    font-size: 1.6rem;
    font-weight: 500;
    color: #5f5f5f;
}

.input {
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    border: 1px solid #eee;
    width: 100%;
    padding: 1rem 1rem;
    margin-top: 0.5rem;
    border-radius: 5px;
}

.input::placeholder {
    font-size: 1.4rem;
}

.inputFile {
    border: 1px solid #eee;
    padding: 1rem;
    border-radius: 4px;
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
}

.categoryBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
}

.associatedSectorChips {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.startupTeamBtn {
    margin: 1rem 0;
    padding: 1rem 2rem;
    border-radius: 10px;
    background: #056ffa;
    color: #fff;
    font-size: 1.4rem;
    display: inline-flex;
    align-items: center;
    text-align: center;
    gap: 1rem;
    cursor: pointer;
}

.formTeamCard{
    display: flex;
    flex-direction: row;
    width: 250px;
    gap: 1rem;
    justify-content: flex-start;
    height: 150px;
}

.startupTeamWrap{
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
}

.startupFaqBtn {
    margin: 1rem 0;
    padding: 1rem 2rem;
    border-radius: 10px;
    background: #056ffa;
    color: #fff;
    font-size: 1.4rem;
    display: inline-flex;
    align-items: center;
    text-align: center;
    gap: 1rem;
    cursor: pointer;
}

.formFaqCard{
    width: 250px;
    height: auto;
}

@media screen and (max-width: 750px) {
    .heading {
        font-size: 30px;
    }

    .heading::after {
        width: 300px;
    }

    .form_container {
        margin: 4rem 5rem;
    }
}