/* Common classes */
.section {
    padding: 2rem 4rem;
    margin-top: 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
}

.sectionWrap {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.heading {
    font-size: 2.4rem;
}

.subHeading {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
}

.inputGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    row-gap: 1rem;
}

.field {
    width: 48%;
}

.ffield {
    width: 100%;
}


/* Section and container */


.container {
    width: 100%;
    padding: 2rem;

}

.firstSection{
    margin-top: 100px;
}



.startupProfileHero {
    background: #f5f5f5;
    border-radius: 10px;
    height: 100px;
    margin: 2rem 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.sphTag {
    font-weight: 800;
    font-size: 1.6rem;
    color: #9f9f9f73;
}

.startupProfileHeroWrap {
    position: absolute;
    padding: 2rem;
    top: 30px;
    left: 0;
    width: 100%;
}

.sphwImg {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 4px solid #fff;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.sphwImg img {
    width: 80%;
    border-radius: 50%;
}

.sphwCom {
    position: absolute;
    left: 150px;
    top: 60px;
    width: calc(100% - 150px);
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .sphwcBtn{
    padding: .8rem 2rem;
    background: #056ffa;
    color: #fff;
    border-radius: 10px;
} */

.sphwComWrap {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: flex-start;
}

.sphwcBtnWrap {
    position: relative;
}

.sphwcBtnWrap input {
    width: 100px !important;
    padding: .8rem 1rem;
    border-radius: 8px;
}

.sphwcBtnWrap label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #056ffa;
    color: #fff;
    font-weight: 500;
    font-size: 1.32rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sphwcName {
    font-weight: 700;
    font-size: 2rem;
}

.sphwcUser {
    font-size: 1.2rem;
    color: #777;
    font-weight: 500;
}

.underline {
    height: 2px;
    width: 100%;
    background: #eee;
}

.buttonContainer {
    margin-top: 4rem;
    display: flex;
    column-gap: 2rem;
    justify-content: center;

}

/* Edit Associated sectors */
.associatedSectorChips {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

@media (max-width:600px) {
    .section {
        padding: 2rem 2rem;
    }

    .inputGroup {
        flex-direction: column;
    }

    .field {
        width: 100%;
    }

    .firstSection {
        margin-top: 140px;
    }

    .startupProfileHeroWrap {
        top: 50px;
    }

    .sphwCom {
        top: 30px;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 400px) {

    .sphwCom {
        position: absolute;
        top: 100px;
        left: 2rem;
        width: 100%;
    }

    .sphTag {
        font-size: 1.2rem;
        width: 100%;
    }

    .firstSection {
        margin-top: 180px;
    }

    .sphwImg {
        width: 100px;
        height: 100px;
    }

    .buttonContainer {
        flex-direction: column;
        gap: 1.4rem;
    }
}