
/* Portfolio component CSS */
.portfolioContainer{
    margin-top: 4rem;
}
.portfolioContainer>h1{
    font-size: 2.4rem;
    font-weight: 500;
    color: #303030;

}
.portfolioBox{
    margin-top: 2rem;
    padding:2rem;
    width: 100%;
    background-color: #F4F9FF;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    border-bottom: 3px solid #E0E0E0;
    display: flex;
}
.portfolioBoxSpan{
    padding-left: 2rem;
    padding-right: 4rem;
    border-right: 1px solid #E0E0E0;
}
.portfolioBoxSpan:last-child{
    border-right: 0px;
}
.portfolioBoxSpan>h5{
    text-transform: uppercase;
    color: #9E9E9E;
    font-weight: 600;
    font-size: 1.4rem;

}
.portfolioBoxSpan>h3{
    color: #303030;
    font-weight: 500;
    font-size: 1.6rem;
    margin-top: 0.5rem;
}

.portfolioBoxInvestor{
    margin-top: 4rem;
}
.portfolioBoxInvestor>h2{
    margin-top: 2rem;
    font-size: 1.8rem;
    color: #303030;
    font-weight: 600;
}
.portfolioBoxInvestor>div{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 2rem;
}
.portfolioIncubateeStartups{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1rem;

}
.portfolioIncubateeStartups>div{
    width: 32%;
}

@media screen and (max-width : 768px) {
    .portfolioIncubateeStartups>div{
        width: 49%;
    }
    .portfolioBox{
        display: flex;
        flex-direction: column;
    }
    .portfolioBoxSpan{
        padding-top: 1rem;
        padding-left: 2rem;
        padding-right: 0rem;
        padding-bottom: 2rem;
        border-right: 0px;
        border-bottom: 1px solid #E0E0E0;
    }
    .portfolioBoxSpan:last-child{
        border-bottom: 0px;

    }
}
@media screen and (max-width : 425px) {
    .portfolioIncubateeStartups>div{
        width: 100%;
    }
}