.lostPage{
    width: 100%;
    max-height: 90vh;
    min-height: 90vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

.lostPage img{
    width: 500px;
}

.lostPageText{
    display: flex;
    width: 50%;
    flex-direction: column;
    gap: 1rem;
}

.lostText{
    font-size: 4rem;
    font-weight: 700;
}

.lostSubText{
    font-size: 1.72rem;
    font-weight: 500;
    width: 450px;
    color: #777;
}

.lostBtn{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #056ffa;
    padding: .8rem 2rem;
    color: #fff;
    font-size: 1.6rem;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .lostPage{
        flex-direction: column;
        gap: 0;
    }

    .lostPage img{
        width: 100%;
    }

    .lostPageText{
        margin: 0 2rem;
    }

    .lostSubText{
        width: 100%;
    }
}