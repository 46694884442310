.container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.6rem;
    border-radius: 5px;
    
}
.buttonContainer{
    font-size: inherit;
    padding: 1rem 2rem;
    width: 100%;
    height: 100%;
    background-color: white;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: inherit;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 1s ease;
    /* min-width: 150px; */
    border: 0.5px solid black;
}
.droplistContainer{
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    max-height: 20rem;
    min-width: 15rem;
    overflow: scroll;
    top: 100%;
    z-index: 1;
}

.listitem{
    padding: 0.4rem 0.5rem;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    transition: background-color 1s ease;
    cursor: pointer;
}

.listitem:hover {
    background-color: #f1f1f1;
}