.container {
    width: 100%;
}

.searchSelectcontainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
}

.chipContainer {
    margin-top: 3rem;
    display: flex;
    gap: 1rem;
}

.clearAllButton {
    color: #056ffa;
    font-size: 1.8rem;
    justify-self: right;
    font-weight: 600;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
}

.searchBox{
    width: 60%;
}

.dropdownBox{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 1.6rem;
}

@media (max-width: 540px) {
    .searchSelectcontainer{
        flex-direction: column;
        gap: 20px;
    }

    .searchBox{
        width: 100%;
    }

    .dropdownBox{
        width: 100%;
        font-size: 1rem;
    }
}