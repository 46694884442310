.incubatorCardContainer{
    border: 1px solid #E0E0E0;
    border-bottom: 3px solid #E0E0E0;
    border-radius: 5px;
    padding: 1.5rem;
    min-width: 250px;
    width: 400px;
    cursor: pointer;
}
.incubatorCardContainer:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;;
}
.incubatorNameLogoBox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.incubatorNameLogoBox>img{
    width: 5rem;
    /* height: 5rem; */
    border-radius: 5px;
}
.incubatorNameAndLocation{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.incubatorNameAndLocation>h2{
    font-size: 1.6rem;
    color: #303030;
    font-weight: 600;

}
.incubatorNameAndLocation>h3{
    font-size: 1.2rem;
    color: #9E9E9E;
    font-weight: 500;
}

.incubatorCardDescription{
    display: inline-block;
    margin-top: 2rem;
    font-size: 1.4rem;
    color: #303030;
    font-weight: 400;

}
.incubatorCardFocusSectors{
    margin-top: 1rem;
    
}
.incubatorCardFocusSectors>h3{
    font-size: 1.2rem;
    font-weight: 500;
    color: #616161;
    
}
.incubatorCardFocusSectors>div{
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;

}
.incubatorCardInvestmentAndIncubatee{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.incubatorCardInvestmentAndIncubatee>div{
    display: flex;
    flex-direction: column;
}
.incubatorCardInvestmentAndIncubatee>div>h3{
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #9E9E9E;
    font-weight: 500;
}
.incubatorCardInvestmentAndIncubatee>div>h2{
    font-size: 1.4rem;
    color: #616161;
    font-weight: 400;
}