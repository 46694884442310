.blogPage {
    display: flex;
    padding: 4rem 0;
    width: 70%;
    margin: 0 auto;
    flex-direction: column;
    gap: 2rem;
}

.blogBasic {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.bbLink {
    display: flex;
    align-items: center;
    font-size: 1.32rem;
    gap: .5rem;
}

.bbLink a {
    text-decoration: none;
    text-transform: capitalize;
    color: #056ffa;
}

.bbHeading {
    font-size: 4rem;
    font-weight: 700;
}

.bbEditor {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    gap: .7rem;
}

.bbEditor img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.bbDate {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    gap: .7rem;
    color: #777;
}

.blogMain {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    font-size: 1.6rem;
}

.blogMain img {
    width: 80%;
    border-radius: 10px;
}

.blogMain img.mainImg{
    width: 100%;
}

.bmHeading {
    font-size: 3.6rem;
    font-weight: 700;
}

.bmSubHeading{
    font-size: 2.8rem;
    font-weight: 700;
}

.blogMain ul li{
    padding: 1rem 0;
}

.blogMore{
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.blogMoreWrap{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 700px) {
    .blogPage {
        width: 90%;
    }

    .bbHeading {
        font-size: 3.4rem;
    }

    .blogMain {
        font-size: 1.4rem;
    }

    .bmHeading {
        font-size: 2.8rem;
    }
}