.sidebar {
    width: 250px;
    padding: 0rem 2rem;
    position: fixed;
    background-color: #fff;
    height: 100%;
}

.sidebarList {
    padding-top: 4rem;
}

.sidebarItem {
    margin: .5rem 0;
    padding: .5rem 2rem;
    font-weight: 500;
    list-style-type: none;
    font-size: 1.4rem;
    gap: 1rem;
    cursor: pointer;
    color: #aaa;
}

.sidebarItemActive{
    color: #000;
}