.blogType2 {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.bT2Img {
    width: 200px;
}

.bT2Img img {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.bT2Text {
    display: flex;
    flex-direction: column;
    gap: .6rem;
}

.bT2TTop {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.dot {
    height: 6px;
    width: 6px;
    background: #777;
    border-radius: 50%;
}

.bT2TTDiv {
    font-weight: 600;
    font-size: 1.2rem;
    color: #777;
}

.bT2THeading {
    font-size: 1.6rem;
    font-weight: 600;
}

.bT2TBottom {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.bT2TBLC {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.4rem;
    gap: .6rem;
}

@media (max-width: 700px) {
    .bT2Img{
        width: 140px;
    }

    .bT2THeading{
        font-size: 1.4rem;
    }
}