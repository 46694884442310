/* Common classes */
.section {
    padding: 2rem 4rem;
    margin-top: 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
}
.sectionWrap {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.heading {
    font-size: 2.4rem;
}

.subHeading {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
}
.inputGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    row-gap: 1rem;
}

.field {
    width: 48%;
}

.ffield {
    width: 100%;
}


/* Section and container */


.container {
    width: 100%;
    padding: 2rem;

}

.toogleButtonContainer{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 1.6rem;
    font-weight: 500;
}

.toogleButton{
    display: flex;
    align-items: center;
}

.buttonContainer {
    margin-top: 6rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
}

@media (max-width: 480px) {
    .buttonContainer {
        flex-direction: column;
        gap: 1.4rem;
    }
}