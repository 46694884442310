.whyJoinCard{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: #F4F9FF;
    width: 320px;
    border-radius: 14px;
    gap: 1rem;
}

.wjcTop{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.wjctIcon{
    display: flex;
    align-items: center;
    font-size: 3.6rem;
}

.wjctText{
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
}

.wjcBottom{
    font-size: 1.32rem;
}

@media (max-width: 480px) {
    .whyJoinCard{
        width: 100%;
    }
}