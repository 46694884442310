.mainHeading {
    font-size: 3.2rem;
    font-weight: 600;
}

.titleHeading {
    font-size: 1.8rem;
    font-weight: 800;
    color: #000;
    margin-bottom: 3rem;

}

.inputGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

}

.inputGroupColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;

}

.field {
    width: 48%;
}

@media (max-width:600px) {
    .inputGroup {
        flex-direction: column;
    }

    .field {
        width: 100%;
    }
}

.container {
    margin: 4rem 4rem
}

.section {
    padding: 2rem 4rem;
    margin-top: 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
}

.photoSection {
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 100px;
    position: relative;
}

.photoSectionWrap {
    /* background: red; */
    padding: 1rem 2rem;
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
}

.photoSectionWrap img {
    width: 150px;
    border: 6px solid #fff;
    height: 150px;
    border-radius: 50%;
}

.pswText {
    position: absolute;
    top: 80px;
    left: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 200px);
}


.pswBtnWrap {
    position: relative;
}

.pswBtnWrap input {
    width: 100px !important;
    padding: .8rem 1rem;
    border-radius: 8px;
}

.pswBtnWrap label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #056ffa;
    color: #fff;
    font-weight: 500;
    font-size: 1.32rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.generalDetailSection {
    margin-top: 12rem;


}

@media (max-width: 1100px) {
    .pswText {
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }

    .generalDetailSection {
        margin-top: 14rem;
    }
}

.personalDetailSection {
    margin-top: 3rem;


}

.socialMediaSection {
    margin-top: 3rem;


}

.interestedSectorChips {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}


.buttonContainer {
    margin-top: 6rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
}




/* Media queries */
@media screen and (max-width: 1000px) {
    .photoSection {
        display: flex;
        flex-direction: column;
    }
}


@media (max-width: 480px) {
    .mainHeading {
        font-size: 2.8rem;
    }

    .titleHeading {
        font-size: 1.6rem;
    }

    .container {
        margin: 2rem 2rem;
    }

    .section {
        padding: 2rem 2rem;
    }

    .generalDetailSection{
        margin-top: 22rem;
    }

    .pswText {
        position: absolute;
        top: 160px;
        left: 2rem;
        width: 100%;
    }
}