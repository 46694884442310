.modalBoxWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #c0bebe1d;
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;
}

.modalBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 4rem 2rem 2rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    width: 400px;
}

.closeModal {
    position: absolute;
    top: -3rem;
    right: -2rem;
    font-size: 2.8rem;
    cursor: pointer;
}

.copyDiv{
    display: flex;
    align-items: center;
    width: 90%;
    position: relative;
}

.copyDiv input{
    padding: 1rem 1.4rem;
    border: 1px solid #ddd;
    border-radius: 10px 0 0 10px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    width: 100%;
}

.copyDiv button{
    padding: 1rem 1.4rem;
    border: 1px solid #ddd;
    border-radius: 0 10px 10px 0;
    outline: none;
    background: #056ffa;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.copyDiv .copied{
    background: #1eb462;
}

.shareSocial{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
    margin-top: 2rem;
    font-size: 1.4rem;
}

.ssWrap{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ssSubWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 2rem;
}

.shareSocial .ssw{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1eb462;
    color: #fff;
}

.shareSocial .ssf{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3b5998;
    color: #fff;
}

.shareSocial .sst{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00acee;
    color: #fff;
}

.shareSocial .ssi{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
    color: #fff;
}

.ssqr img{
    width: 90px;
    height: 90px;
}


@media (max-width: 600px) {
    .modalBox {
        width: 80%;
        padding: 2rem;
    }

    .shareSocial{
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
}