

.container{
    /* flex: 4; */
    width: 250px;
    padding: 2rem;
    background-color: white;
    position: relative;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(207, 207, 207);

}
.section1{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;


}
.logo{
    height: 35px;
    background-color: black;
    padding: 5px;
    border-radius: 10%;
}
.heading{
    font-size: 18px;
}
.tags{
    width: 100%;
    margin-top : 20px;
    display: flex;
    flex-wrap:wrap;
}
.description{
    margin-top: 10px;
    font-size: 14px;

}
.footer{
    margin: auto;
    position: absolute;
    bottom: 15px;
    left: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.visibilityNumber{
    color: #D0CFCF;
    font-size: 12px;
    margin-left: 5px;
}