.blogType3{
    position: relative;
    border: 8px;
    overflow: hidden;
    min-width: 380px;
    max-width: 380px;
    height: 260px;
    object-fit: cover;
}

.blogType3 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bT3Overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(transparent, #000);
}

.bT3Text{
position: absolute;
bottom: 0;
left: 0;
padding: .6rem 1rem;
color: #fff;
font-size: 2.4rem;
font-weight: 700;
}

@media (max-width: 700px) {

    .blogType3{
        max-width: 280px;
        min-width: 280px;
        height: 200px;
    }
    .bT3Text{
        font-size: 2rem;
    }
}