.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  background-color: #fff;
  padding: 1.5rem 4rem;
  z-index: 10;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, .2);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a.logoWrap {
  position: relative;
  text-decoration: none;
  color: inherit;
}

.logoBeta {
  position: absolute;
  top: -1rem;
  left: -1rem;
  /* bottom: -1rem;
  font-size: 1.2rem;
  right: 2.6rem;
  background: #ddd;
  padding: .2rem 1rem;
  font-weight: 500;
  border-radius: 10px 0 10px 0; */
}

.logoBeta img {
  width: 32px;
}

.logo {
  width: 100px;
}

.logo img {
  height: 2.8rem;
}

.rightNav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.rightNavExtra {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.toggleButton {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  z-index: 200000;
}

.toggleIcon {
  background-color: #333;
  height: 3px;
}

.navLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  list-style: none;
  gap: 2rem;
  font-weight: 500;
  position: relative;
}

.navLinks li {
  cursor: pointer;
  position: relative;
}

.navLinks .navImageDropdown {
  position: absolute;
  top: 50px;
  right: 0px;
}

.sidebar {
  list-style: none;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.sidebarLogo {
  padding: 1.8rem 4rem;
  border-bottom: 1px solid #ddd;
}

.sidebarLogo img {
  height: 2.8rem;
}

.sidebarClosed {
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;
  background-color: #f8f8f8;
}

.sidebarOpen {
  transform: translateX(0%);
  transition: transform 0.3s ease-out;
  top: 0%;
  left: 0px;
  /* bottom: 0px; */
  background-color: #f8f8f8;
  /* padding: 10px 0; */
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.sidebarDisplay {
  display: none;
}

.sidebar li {
  /* margin-bottom: 10px; */
  padding: 1.32rem 2rem;
  padding-left: 4rem;
  display: flex;
  gap: .6rem;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  /* border-top: 1px solid #bbb; */
}

.dropdownListImage ul li{
  padding-left: 6rem;
  display: flex;
  gap: 1rem;
  position: relative;
}

.dropdownListImage ul li .liWrap{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff00;
  cursor: not-allowed;
}

.dropdownListImage ul li img{
  width: 40px;
}

.dropdownListImage ul li .text{
  display: flex;
  flex-direction: column;
  gap: .4rem;
}

.dropdownListImage ul li .text .para{
    font-size: 1.2rem;
    font-weight: 400;
    width: 140px;
}

.dropdownList ul li {
  padding-left: 6rem;
}

.sidebar li:hover {
  background: #ddd;
}

.sideNavLink {
  position: relative;
  height: 100%;
}

.navExtra {
  position: absolute;
  bottom: 8rem;
  display: flex;
  gap: 2rem;
  padding-left: 4rem;
  left: 0;
}

/* .sidebarOpen li ul {
  list-style: none;
  padding: 1rem;
}

.sidebarOpen li ul li {
  margin-bottom: 10px;
  margin: 0;
  padding: .8rem 0;
  display: flex;
  align-items: center;
  gap: .8rem;
} */

@media (max-width: 768px) {
  .toggleButton {
    display: flex;
  }

  .navLinks {
    display: none;
  }

  .rightNavExtra {
    display: none;
  }

  .sidebarDisplay {
    display: flex;
  }

  .sidebarClosed {
    transform: translateX(-100%);
    /* opacity: 0; */
    /* transition: transform 0.3s ease-in, opacity 0.3s ease-in; */
    transition: transform 0.3s ease-in;
  }

  .sidebarOpen {
    /* transition: transform 0.3s ease-out, opacity 0.3s ease-out; */
    transition: transform 0.3s ease-out;
  }

}