/* About component */

.aboutContainer{
    margin-top: 4rem;
}
.aboutContainer>h1{
    font-size: 2.4rem;
    font-weight: 500;
    color: #303030;

}
.aboutBox{
    margin-top: 4rem;
}
.aboutCarouselAndText{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.aboutCarouselAndText>div:first-child{
    width: 30%;
}
.aboutCarouselAndText>div:last-child{
    width: 65%;
}
.aboutText{
    font-size: 1.6rem;
    color: #303030;
    font-weight: 400;

}
.aboutTextHeading{
    font-size: 1.8rem;
    color: #303030;
    font-weight: 600;
}

@media screen and (max-width : 768px) {
    .aboutCarouselAndText{
        flex-direction: column-reverse;
    }
    .aboutCarouselAndText>div:first-child{
        width: 100%;
        margin-top: 2rem;

    }
    .aboutCarouselAndText>div:last-child{
        width: 100%;
    }
}