.container {
    /* margin: 0rem 12rem; */

}

.headingContainer {
    /* margin-top:5rem; */

}

.heading {
    font-size: 2.4rem;
    font-weight: 800;
    line-height: 3rem;
}

.heading2{
    width: 50rem;
    margin-top: 1rem;
    font-size: 1.6rem;
}

.sectionSearchSelectbox{
    margin-top: 2rem;
    width: 100%;
}

.sectionStartupGrid{
    margin-top: 2rem;
    margin-bottom: 10rem;
}

.cardsSkeletonWrap{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    align-items: center;
    justify-content: space-around;
}


@media screen and (max-width:1300px) {
    .container{
        /* margin: 0rem 6rem; */
    }
    
}

@media (max-width: 480px) {
    .container{
        margin: 0rem;
    }

    .headingContainer {
        margin-top:3.4rem;
    }
    
    .heading {
        font-size: 2.4rem;
        line-height: 120%;
    }
    
    .heading2{
        font-size: 1.2rem;
        width: 100%;
    }
    
    .sectionSearchSelectbox{
        margin-top: 2rem;
        width: 100%;
    }
    
    .sectionStartupGrid{
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

}





/* DEAL REQUEST MODAL */
.dealRequestModalWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #c0bebe1d;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;
}

.dealRequestModalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    width: 400px;
    position: relative;

}

.closeModal {
    position: absolute;
    top: -3rem;
    right: -2rem;
    font-size: 2.8rem;
    cursor: pointer;
}

.dealRequestModalContainer>h2{
    font-size: 2.4rem;
    font-weight: 600;
}
.dealRequestModalContainer>button{
    margin-top: 1rem;
    padding: 1rem 2rem;
    border-radius: 5px;
    background-color: #056ffa;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
}



@media (max-width: 600px) {
    .DealRequestModalWrap {
        width: 80%;
        padding: 2rem;
    }
}