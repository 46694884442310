.container{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 4rem;
    height: 4rem;
}

.avatar{
    border-radius: 100%;
    width: inherit;
    height: inherit;

}

.dropdownList{
    background-color: #fff;
    z-index: 1;
    padding: 2rem 1rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    list-style: none;
    font-size: 1.4rem;
    position: absolute;
    top: 100%;
    right: -3rem;
    min-width: 20rem;

}
.dropdownList li{
    padding: 1rem 1rem;
    border-radius: 5px;
    font-size: inherit;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

}
.dropdownList li:hover{
    background-color: #eee;

}