.mainHeading {
    font-size: 3.2rem;
    font-weight: 600;
}

.titleHeading {
    font-size: 1.8rem;
    font-weight: 800;
    color: #000;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}

.titleHeading span {
    font-weight: 500;
    text-transform: uppercase;
    background: rgba(255, 227, 100, 0.616);
    color: goldenrod;
    font-size: 1.2rem;
    border-radius: 100px;
    padding: .4rem 1rem;
    margin: 0 2rem;
    text-align: center;
}

.container {
    margin: 4rem 4rem
}

.inputGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.field {
    width: 48%;
}

.ffield {
    width: 100%;
}

.settingSingleField {
    margin: 2rem 0;
    padding: 2rem 4rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
    position: relative;
}

.settingSingleFieldWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.05);
    cursor: not-allowed;
}

.radioGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.rfield {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
    min-width: 200px;
    max-width: 300px;
    padding: 1rem;
    border-radius: 8px;
}

.redRadio {
    background: #ff3a3a2a;
    border: 1px solid #ff232367;
}

.greenRadio {
    background: #36ff9b3d;
    border: 1px solid #00ff8056;
}

.yellowRadio {
    background: #fdc34444;
    border: 1px solid #ffae0091;
}


.buttonContainer {
    margin-top: 6rem;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.rfield label {
    display: flex;
    flex-direction: column;
}

.radioHeading {
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
}

.radioPara {
    font-size: 1.2rem;
    font-weight: 500;
}


@media (max-width: 480px) {
    .mainHeading {
        font-size: 2.8rem;
    }

    .titleHeading {
        font-size: 1.6rem;
    }

    .container {
        margin: 2rem 2rem;
    }

    .settingSingleField {
        padding: 2rem 2rem;
    }

    .inputGroup {
        flex-direction: column;
    }

    .field {
        width: 100%;
    }

    .buttonContainer {
        flex-direction: column;
    }
}