.container {
    width: 100%;

}

.heading {
    font-size: 2.4rem;
    padding: 0rem 2rem;
}

.fieldGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
}

.fieldGroupNew {
    padding: 0 2rem;
}

.field {
    padding: 0px 2rem;
    width: 49%;
}

/* .table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1.4rem;
    font-family: sans-serif;
    min-width: 400px;
    width: 100%;

}

.table>thead tr {
    background-color: #056ffa;
    color: #ffffff;
    text-align: left;
}

.table th,
.table td {
    padding: 12px 15px;
}

.table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.table tbody tr:last-of-type {
    border-bottom: 2px solid #056ffa;
} */

/* TableComponent.module.css */

.table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    font-size: 1.4rem;
}

.table th,
.table td {
    border: 1px solid #ccc;
    text-align: center;
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.table input {
    width: 100%;
    border: none;
    padding: 1rem;
    text-align: center;
}

.buttonContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 1rem 0;
}


@media screen and (max-width: 768px) {
    .fieldGroup {
        flex-direction: column;
    }

    .field {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .buttonContainer {
        flex-direction: column;
        gap: 1.4rem;
    }
}