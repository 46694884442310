/* dealTerm component */

.dealTermContainer{
    margin-top: 4rem;
}
.dealTermContainer>h1{
    font-size: 2.4rem;
    font-weight: 500;
    color: #303030;

}
.dealTermText{
    border-bottom: 2px solid #e0e0e0;
    padding: 2rem 0rem 1rem;
}
.dealTermText>h1{
    font-size: 1.8rem;
    color: #056ffa;
    font-weight: 600;

}
.dealTermTextHeading{
    font-size: 1.4rem;
    color: #999;
    font-weight: 600;
    text-transform: uppercase;
}

