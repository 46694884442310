.incubateeCardContainer{
    border: 1px solid #E0E0E0;
    border-bottom: 3px solid #E0E0E0;
    border-radius: 5px;
    padding: 2rem;
    min-width: 200px;
    cursor: pointer;

}
.incubateeCardContainer:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;;
}
.incubateeCardImageNameContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.incubateeCardImageNameContainer>img{
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    
}
.incubateeCardImageNameContainer>div{
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}
.incubateeCardImageNameContainer>div>h2{
    font-size: 1.4rem;
    font-weight: 600;
    color: #303030;
}
.incubateeCardImageNameContainer>div>h3{
    font-size: 1.2rem;
    font-weight: 500;
    color: #9E9E9E;
}
