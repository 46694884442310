/* Team component */
.teamContainer{
    margin-top: 4rem;

}
.teamContainer>h1{
    font-size: 2.4rem;
    font-weight: 500;
    color: #303030;

}
.teamBox{
    margin-top: 2rem;
}
.teamBox>h2{
    font-size: 1.8rem;
    font-weight: 600;
    color: #303030;

}
.teamBoxSection{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    justify-content: space-between;
}
.teamBoxSection>div{
    width: 49%;
}

@media screen and (max-width : 768px) {
    .teamBoxSection>div{
        width: 100%;
    }
}