

.documentModalBoxWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #c0bebe1d;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;
}

.documentModalBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 4rem 2rem 2rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    width: 70%;
    height: 60%;
}

.closeModal {
    position: absolute;
    top: 1rem;
    right: 2rem;
    font-size: 2.8rem;
    cursor: pointer;
}

.documentModalPitchContainer{
    width: 90%;
    /* height: 80%; */
}





@media (max-width: 600px) {
    .documentModalBox {
        width: 95%;
    }

}