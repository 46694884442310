.outerContainer {
    max-width: 1440px;
    margin: auto;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1.4rem;
    height: 100%;
    background-color: #f5f5f5;
    min-height: calc(100vh - 8rem);
    padding-top: 1.4rem;
}

.leftContainer {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-top-right-radius: 10px;
}

.rightContainer {
    flex: 1;
    background-color: #fff;
    border-top-left-radius: 10px;
}

.outletContainer{
    padding: 4rem;
}

.heading{
    font-size: 3.2rem;
    font-weight: 700;
}

.subHeading{
    font-size: 2.2rem;
    font-weight: 700;
}

.para{
    font-size: 1.6rem !important;
    color: #777;
    padding: 2rem 0;
}

.paraList{
    padding-left: 4rem;
    display: flex;
    align-items: center;
    gap: .4rem;
}

.note{
    display: inline;
    padding: .6rem 2rem;
    font-size: 1.6rem;
    font-weight: 500;
    border: 1px solid #000;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .leftContainer {
        display: none;
    }
}