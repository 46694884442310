.container{
    position: relative;
    background-color: rgb(0, 0, 0);
    height: 400px;
    width: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(207, 207, 207);
    overflow: hidden;
}
a.container{
    text-decoration: none;
    color: inherit;
}
.cardImage{
    /* flex: 3; */
    height: 180px;
    object-fit:fill;
    
}
.cardBody{
    /* flex: 4; */
    height: 220px;
    padding: 10px 15px;
    background-color: white;
}
.section1{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.section1 .logo{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #ccc; */
}

.section1 img{
    width: 100%;
}
/* .logo{
    height: 40px;
    background-color: black;
    padding: 5px;
    border-radius: 10%;
} */

/* img.logo{
    height: 100%;
} */
.heading{
    font-size: 1.6rem;
}
.tags{
    margin-top : 20px;
}
.description{
    margin-top: 10px;
    font-size: 1.2rem;

}
.footer{
    margin: auto;
    position: absolute;
    bottom: 15px;
    left: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.visibilityNumber{
    color: #D0CFCF;
    font-size: 12px;
    margin-left: 5px;
}

@media (max-width: 540px) {
    .container{
        width: 100%;
    }
}