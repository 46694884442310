.ssCard{
    display: inline-flex;
    padding: 1rem 2rem;
    align-items: center;
    gap: 1rem;
    background: #F4F9FF;
    font-size: 1.32rem;
    border-radius: 5px;
    font-weight: 600;
}

.sscIcon{
    display: flex;
    align-items: center;
}