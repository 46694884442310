.container {
    width: 100%;
    padding: 2rem;
    position: relative;
}

.heading {
    font-size: 2.4rem;
}

.subHeading {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
}

.startupDocBtn {
    position: absolute;
    top: 1rem;
    right: 2rem;
    margin: 1rem 0;
    padding: 1rem 2rem;
    border-radius: 10px;
    background: #056ffa;
    color: #fff;
    font-size: 1.4rem;
    display: inline-flex;
    align-items: center;
    text-align: center;
    gap: 1rem;
    cursor: pointer;
}

.startupDocWrap {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
}

.buttonContainer {
    margin-top: 6rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
}

@media (max-width: 600px) {
    .startupDocBtn {
        position: absolute;
        top: 6rem;
        left: 2rem;
        display: inline-flex;
        width: 180px;
    }

    .startupDocWrap {
        margin-top: 8rem;
    }

    .buttonContainer {
        flex-direction: column;
        gap: 1.4rem;
    }
}