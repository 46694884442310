.authPage{
    background-image: url('../../../public/img/abg1.jpg');
    /* opacity: .8; */
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    position: relative;
}

img.topLogo{
    position: absolute;
    top: 2rem;
    left: 4rem;
    width: 100px;
}

.authForm {
    padding: 2rem 4rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;
    width: 400px;
}

.authHeading {
    font-size: 2.8rem;
    font-weight: 700;
}

.authSubHeading {
    font-size: 1.6rem;
    font-weight: 600;
    color: #aaa;
}

.authSubSubHeading {
    font-size: 1.6rem;
}

.authPara{
    font-size: 1.4rem;
    color: #777;
}

.authForm>form {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.authForm>form button{
    margin: 1rem 0;
}

.forgotPwd{
    font-size: 1.4rem;
    width: 90%;
    text-align: left;
}

.authForm a {
    color: #056ffa;
}

.inputField {
    width: 100%;
}

.otpField {
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-size: 2rem;
}

.otpField input {
    border: none;
    outline: none;
    font-family: 'Poppins', sans-serif;
    border: 1px solid #222;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    text-align: center;
}

.inputBtn {
    font-size: 1.4rem;
    border: none;
    outline: none;
    background: #056ffa;
    padding: .8rem 1rem;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

.gForm {
    border: 1px solid #222;
    border-radius: 6px;
    padding: .8rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    gap: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.gForm img {
    width: 20px;
    height: 20px;
}

@media (max-width: 600px) {
    .authPage {
        padding: 1rem;
    }

    .authForm {
        padding: 1rem 2rem;
        width: 80%;
    }

    .authForm form {
        width: 100%;
    }
}