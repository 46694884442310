/* FAQ Container CSS */
.faqContainer{
    margin-top: 4rem;

}
.faqContainer>h1{
    font-size: 2.4rem;
    font-weight: 500;
    color: #303030;


}
.faqContainer>div{
    margin-top: 2rem;
    /* width: 70%; */
}