.comCarousel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.ccItem {
    width: 100%;
    height: 350px;
    border-radius: 10px;
    /* overflow: hidden; */
}

.ccItemHide {
    display: none;
    border-radius: 10px;
    overflow: hidden;
}

.carrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 3.2rem;
    filter: drop-shadow(0px 0px 5px #555);
}

.carrow:hover {
    cursor: pointer;
    color: #ddd;
}

.comIndicator {
    display: flex;
    gap: .5rem;
    position: absolute;
    bottom: -2rem;
    cursor: pointer;
}

.cindicator {
    background: #777;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
}

.cindicatorInActive {
    background: #ddd;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
}

.carrowLeft {
    left: 1rem;
}

.carrowRight {
    right: 1rem;
}


.carousel {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    width: 100%;
}