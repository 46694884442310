.container{
    max-width: 1440px;
    margin: auto;
}
.subContainer{
    margin: 4rem 8rem 0rem 20rem;
}


@media (max-width:1024px) {
    .subContainer{
        margin: 4rem 2rem 0rem 15rem;
    }    
}
@media (max-width:768px) {
    .subContainer{
        margin: 4rem 2rem 0rem 2rem;
    }    
}
