.verifyForm{
    padding: 2rem 0;
    width: 100%;
    margin: 0 auto;
}

.verifyFormHeading{
    font-size: 2.2rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.verifyForm form{
    display: flex;
    flex-direction: column;
    gap: 1.32rem;
}

.verifyForm .inputField{
    display: flex;
    flex-direction: column;
    gap: .75rem;
    width: 100%;
}

.verifyForm label{
    font-size: 1.68rem;
}

.verifyForm input{
    font-family: 'Poppins', sans-serif;
    padding: .8rem 1rem;
    outline: none;
    width: 100%;
}

.verifyForm input::placeholder{
    font-family: 'Poppins', sans-serif;
}