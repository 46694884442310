.stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0 6rem 0;
}

.stepperWrapper1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.stepperWrapper2 {
    display: flex;
    align-items: center;
}

.stepperStep {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(4, 217, 192);
}

.stepperStepIcon {
    height: 50px;
    width: 50px;
    border: 2px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: .5s ease-in-out;
    font-size: 1.72rem;
}

.stepperStepIconSelected{
    background: rgb(4, 217, 192);
    color: #fff;
    border: 2px solid rgb(4, 217, 192);
}

.stepperStepDes {
    position: absolute;
    top: 55px;
    text-align: center;
    font-size: 1.4rem;
    left: 50%;
    transform: translateX(-50%);
    color: #aaa;
}

.stepperStepDesSelected{
    color: rgb(4, 217, 192);
}

.stepperLine {
    display: flex;
    flex: auto;
    border-top: 2px solid #ddd;
    transition: .5s ease-in-out;
}

.stepperLineCompleted{
    border-top: 2px solid rgb(4, 217, 192);
}