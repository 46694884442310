/* Funding CSS */
.noDataFoundImage{
    width: 300px;
    object-fit: contain;
}

.fundingContainer{
    margin-top: 4rem;
}
.fundingContainer>h1{
    font-size: 2.4rem;
    font-weight: 500;
    color: #303030;

}
.fundingBox{
    margin-top: 2rem;
    padding:2rem;
    width: 100%;
    background-color: #F4F9FF;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    border-bottom: 3px solid #E0E0E0;
    display: flex;
}
.fundingBoxSpan{
    padding-left: 2rem;
    padding-right: 4rem;
    border-right: 1px solid #E0E0E0;
}
.fundingBoxSpan:last-child{
    border-right: 0px;
}
.fundingBoxSpan>h5{
    text-transform: uppercase;
    color: #9E9E9E;
    font-weight: 600;
    font-size: 1.4rem;

}
.fundingBoxSpan>h3{
    color: #303030;
    font-weight: 500;
    font-size: 1.6rem;
    margin-top: 0.5rem;
}

.fundingBoxInvestor{
    margin-top: 4rem;
}
.fundingBoxInvestor>h2{
    margin-top: 2rem;
    font-size: 1.8rem;
    color: #303030;
    font-weight: 600;
}
.fundingBoxInvestor>div{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 2rem;
}


@media screen and (max-width : 768px) {
    .fundingBox{
        display: flex;
        flex-direction: column;
    }
    .fundingBoxSpan{
        padding-top: 1rem;
        padding-left: 2rem;
        padding-right: 0rem;
        padding-bottom: 2rem;
        border-right: 0px;
        border-bottom: 1px solid #E0E0E0;
    }
    .fundingBoxSpan:last-child{
        border-bottom: 0px;

    }
}
/* @media screen and (max-width : 425px) {
} */