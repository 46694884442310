.numberCounter {
    font-size: 24px;
    font-weight: 900;
    transition: all 1s ease-in-out;
    opacity: 0;
    transform: translateY(20px);
    color: #000;
  }
  
  .numberCounter.active {
    opacity: 1;
    transform: translateY(0);
  }
  