.reactpdf {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: rgb(244, 244, 243);
    border-radius: 0.5rem;
    overflow-y: auto;
}

.pitchHeading {
    font-weight: 700;
    font-size: 3.2rem;
}

.docTop{
    width: 100%;
    overflow: hidden;
}

.docTop .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 70vw !important;
    height: auto !important;
}

.docBottom {
    width: 70vw;
    margin: 2rem auto;
    position: relative;
}

.docListFull {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    overflow-x: scroll;
}

.docListFull::-webkit-scrollbar {
    height: 5px;
}

.docListFull::-webkit-scrollbar-thumb {
    background: #aaa;
}

.docBottom button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    outline: none;
    background: #222;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #eee;
    font-size: 3.2rem;
    z-index: 2;
}

.docBottom button.btnLeft {
    left: -20px;
}

.docBottom button.btnRight {
    right: calc(0vw - 20px)
}