.investorCardContainer{
    border: 1px solid #E0E0E0;
    border-bottom: 3px solid #E0E0E0;
    border-radius: 5px;
    padding: 1rem;
    min-width: 200px;

}
.investorCardImageNameContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.investorCardImageNameContainer>img{
    width: 2.5rem;
    height: 2.5rem;
    
}
.investorCardImageNameContainer>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
}
.investorCardImageNameContainer>div>h2{
    font-size: 1.4rem;
    font-weight: 600;
    color: #303030;
}
.investorCardImageNameContainer>div>h3{
    font-size: 1.2rem;
    font-weight: 500;
    color: #9E9E9E;
}
.investorCardContainer>span{
    display: inline-block;
    font-size: 1.2rem;
    padding-top: 1rem;

}
