.modalBoxWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #c0bebe59;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;
    overflow: auto;
}

.modalBox {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    font-size: 1.4rem;
    width: 300px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    min-width: 420px;
    padding-top: 2rem;
    overflow: hidden;
}

.closeModal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2.8rem;
    cursor: pointer;
}

.lockModal {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    margin: 0 auto;
}

.lmTop {
    width: 100%;
    padding: 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lmMiddle {
    width: 100%;
    padding: 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lmBottom {
    width: 100%;
    padding: 2rem;
    background: #292929;
    color: #fff;
    text-align: center;
}

.lmHeading {
    font-size: 2.8rem;
    text-align: center;
    font-weight: 700;
}

.lmPara {
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
    color: #aaa;
}

@media (max-width: 480px) {
    .modalBox {
        width: 90%;
        min-width: 90%;
    }
}