.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #f2f2f2;
  margin: 1rem;
  cursor: pointer;
}
