.blogList {
    padding: 2rem 6rem;
}

.bLSection1 {
    padding: 4rem 0;
}

.bLHeading {
    padding-bottom: 2rem;
    font-size: 2.4rem;
    text-transform: uppercase;
    font-weight: 700;
}

.bLS1Wrap {
    display: flex;
    gap: 6rem;
}

.bLS1WLeft {
    width: 50%;
}

.bLS1WRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 2rem;
}

.bLSection2 {
    padding: 4rem 0;
}

.bLS2Wrap{
    display: flex;
    gap: 4rem;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.bLSection3 {
    padding: 4rem 0;
}

.bLS3Wrap{
    display: flex;
    gap: 4rem;
    overflow-x: auto;
}

.bLS3Wrap::-webkit-scrollbar{
    height: 4px;
}


@media (max-width: 700px) {
    .blogList{
        padding: 2rem;
    }

    .bLS1Wrap{
        flex-direction: column;
    }

    .bLS1WLeft{
        width: 100%;
    }

    .bLS1WRight{
        width: 100%;
    }

    .bLS2Wrap{
        align-items: center;
        justify-content: center;
    }

    .bLS3Wrap{
        gap: 2rem;
    }
}