.container {
  width: 100%;
  padding: 2rem;
}

.editor {
  width: 100%;
  min-height: 75vh;
  background-color: rgb(239, 241, 241);
}

