a.serviceCard{
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 1.5rem;
    width: 300px;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 1rem;
    border-radius: 10px;
    text-decoration: none;
    color: inherit;
}

.scIcon{
    min-width: 50px;
    height: 55px;
    text-align: center;
    font-size: 2.4rem;
    background: #056ffa;
    color: #fff;
    line-height: 60px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.scHead{
    font-weight: 600;
    text-transform: capitalize;
    font-size: 1.6rem;
}

.scPara{
    font-size: 1rem;
    color: #777;
}