/* Common classes */
.section {
    padding: 2rem 4rem;
    margin-top: 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
}
.sectionWrap {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.heading {
    font-size: 2.4rem;
}

.subHeading {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
}
.inputGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    row-gap: 1rem;

}

.field {
    width: 48%;
}

.ffield {
    width: 100%;
}



.container {
    width: 100%;
    padding: 2rem;

}



.checkField{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 1.6rem;
    font-weight: 500;
}

.mlaCheck{
    display: flex;
    align-items: center;
}

.directorBtn{
    position: absolute;
    top: 1rem;
    right: 2rem;
    margin: 1rem 0;
    padding: 1rem 2rem;
    border-radius: 10px;
    background: #056ffa;
    color: #fff;
    font-size: 1.4rem;
    display: inline-flex;
    align-items: center;
    text-align: center;
    gap: 1rem;
    cursor: pointer;
}

.buttonContainer {
    margin-top: 6rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
}
.startupTeamWrap{
    margin: 2rem 0;
    display: flex;
    align-items: center;
    gap: 2rem;
}

@media (max-width: 600px) {
    .directorBtn {
        position: absolute;
        top: 6rem;
        left: 2rem;
        display: inline-flex;
        width: 180px;
        text-align: center;
    }

    .startupTeamWrap {
        margin-top: 8rem;
    }
}