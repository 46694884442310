.container{
    width:100%;
    display: flex;
    justify-content: space-around;
    column-gap: 1rem;
    row-gap: 3rem;
    flex-wrap: wrap;
}


