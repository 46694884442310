.container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  /* align-items: center; */
  /* justify-content: center; */
  margin: 0 auto;
  margin-top: 2rem;
  width: 70vw;
}

/* .blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 50px;
  width: 80vw;
  background-color: rgb(255, 239, 239);
  border-radius: 15px;
  padding: 1.5rem;
} */

.blogBasic {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bbLink {
  display: flex;
  align-items: center;
  font-size: 1.32rem;
  gap: .5rem;
}

.bbLink a {
  text-decoration: none;
  text-transform: capitalize;
  color: #056ffa;
}

.bbHeading {
  font-size: 4rem;
  font-weight: 700;
}

.bbEditor {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  gap: .7rem;
}

.bbEditor img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.bbDate {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  gap: .7rem;
  color: #777;
}

.blogThumb {
  width: 100%;
}

.blogThumb img {
  width: 100%;
  border-radius: 12px;
}

.mainBlog {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  /* align-items: flex-start; */
  position: relative;
}

.mBLeft {
  /* min-width: 140px;
  max-width: 140px; */
  display: flex;
  justify-content: center;
  width: 100%;
  /* flex-direction: column; */
  gap: 4rem;
  /* position: sticky; */
  /* top: 120px; */
}

.mBLike {
  display: flex;
  gap: .6rem;
  /* height: 50px; */
  align-items: center;
  justify-content: center;
  /* width: 70px; */
  font-size: 1.8rem;
  cursor: pointer;
}

.mBComment {
  display: flex;
  /* border: 2px solid #000; */
  gap: .6rem;
  /* height: 50px; */
  align-items: center;
  justify-content: center;
  /* width: 70px; */
  font-size: 1.8rem;
}

.mBShare {
  display: flex;
  border: 2px solid #000;
  gap: 1rem;
  height: 50px;
  align-items: center;
  justify-content: center;
  width: 70px;
  font-size: 2rem;
}

.dot{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #777;
}

.mBIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mBRight {
  width: 100%;
  padding-bottom: 2rem;
}

.comments-container {
  border-top: 1px solid #ddd;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 70vw;
  margin: 0 auto;
}

.comments-container h1 {
  font-size: 2.4rem;
}

.comments-content-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin: auto;
  width: 100%;
}

.iconsText {
  font-size: 18px;
}

.hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 700px) {
  .container {
    padding: 4rem;
    width: 100%;
  }

  .bbHeading {
    font-size: 3.4rem;
  }

  .blogMain {
    font-size: 1.4rem;
  }

  .bmHeading {
    font-size: 2.8rem;
  }

  .comments-container h1{
    font-size: 1.8rem;
  }

  .comments-container{
    width: 100%;
    padding: 1rem 4rem;
  }
}

@media (max-width: 480px) {
  .container{
    padding: 2rem;
  }

  .comments-container{
    padding: 1rem 2rem;
  }
}