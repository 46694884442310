.outerContainer {
    max-width: 1440px;
}

.container {
    width: 100%;
    display: flex;
    flex: 1;
    height: 100%;
    background-color: #f5f5f5;
    min-height: calc(100vh - 8rem);
}

.leftContainer {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
}

.leftContainer::-webkit-scrollbar{
    width: 5px;
}

.rightContainer {
    flex: 1;
    margin: 1.5rem 1.5rem 0rem;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* overflow: scroll; */
}

@media (max-width: 768px) {
    .leftContainer {
        display: none;
    }
}