.container {
    /* margin: 0rem 12rem; */

}

.headingContainer {
    /* margin-top:5rem; */

}

.heading {
    font-size: 2.4rem;
    font-weight: 800;
    line-height: 3rem;
}

.heading2{
    width: 50rem;
    margin-top: 1rem;
    font-size: 1.6rem;
}

.sectionSearchSelectbox{
    margin-top: 2rem;
    width: 100%;
}

.sectionStartupGrid{
    margin-top: 2rem;
    margin-bottom: 10rem;
}

.cardsSkeletonWrap{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    align-items: center;
    justify-content: space-around;
}


@media screen and (max-width:1300px) {
    .container{
        /* margin: 0rem 6rem; */
    }
    
}

@media (max-width: 480px) {
    .container{
        margin: 0rem;
    }

    .headingContainer {
        margin-top:3.4rem;
    }
    
    .heading {
        font-size: 2.4rem;
        line-height: 120%;
    }
    
    .heading2{
        font-size: 1.2rem;
        width: 100%;
    }
    
    .sectionSearchSelectbox{
        margin-top: 2rem;
        width: 100%;
    }
    
    .sectionStartupGrid{
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

}