.sectionContainer {
    padding: 2rem 0;
    padding-left: 12rem;
    padding-right: 12rem;
}

.heading {
    font-size: 4.4rem;
    font-weight: 800;
}

.subHeading {
    font-size: 1.8rem;
    font-weight: 600;
    color: #777;
}

.section1 {
    width: 100%;
    display: flex;
    gap: 4rem;
    /* background: #d0e3ff; */
}

.section1Left {
    width: 42%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section1Left>h1 {
    font-size: 4.2rem;
    line-height: 4.4rem;
    color: #000;
    font-weight: 900;
}

.section1Left>p {
    padding-top: 2rem;
    font-size: 1.8rem;
    line-height: 2rem;
    color: #000;
    font-weight: 200;
}

.section1Right {
    width: 58%;
}

.section1Right>img {
    width: 100%;
}

.section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
}

.section2Wrap {
    display: flex;
    align-items: center;
    gap: 4rem;
}

.section2Left img {
    min-height: 90vh;
    max-height: 90vh;
}

.section2Right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

.s2rBox {
    width: 100%;
    padding: 2rem;
    background: #eee;
    border-radius: 14px;
    display: flex;
    gap: 2rem;
}

.s2rbIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    border-radius: 10px;
    font-size: 3.4rem;
}

.s2rbgIcon {
    background: rgba(0, 255, 128, 0.4);
}

.s2rbbIcon {
    background: rgba(0, 174, 255, 0.4);
}

.s2rbrIcon {
    background: rgba(255, 166, 0, 0.4);
}

.s2rbText {
    display: flex;
    align-items: flex-start;
    text-align: left;
    flex-direction: column;
    gap: 1rem;
}

.s2rbtHeading {
    font-size: 2rem;
    font-weight: 700;
}

.s2rbtPara {
    font-size: 1.4rem;
}

.section3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4rem;
}

.section3Wrap {
    display: flex;
    align-items: center;
    gap: 4rem;
    padding-bottom: 6rem;
}

.section3Wrap>h1 {
    width: 70%;
    text-align: left;
    color: #5f5f5f;
}

.s3Box {
    width: 230px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
}

.s3Box:nth-child(even) {
    flex-direction: column-reverse;
}

.s3bnName {
    font-size: 2rem;
    font-weight: 500;
}

.s3bnTech {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 700;
}

.s3bImg {
    width: 100%;
}

.s3bImg img {
    width: 100%;
    border-radius: 10px;
}

@media screen and (max-width:1100px) {
    .sectionContainer {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

@media screen and (max-width:768px) {
    .sectionContainer {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .section1 {
        flex-direction: column;
    }

    .section1Left {
        width: 100%;
    }

    .section1Left>h1 {
        font-size: 3.2rem;
        line-height: 3.4rem;
    }

    .section1Right {
        width: 100%;
    }

    .section2Wrap {
        flex-direction: column;
    }

    .section3Wrap {
        flex-direction: column;
    }

    .section3Wrap>h1 {
        width: 100%;
    }

    .heading {
        font-size: 3.2rem;
        line-height: 3.4rem;
    }

    .s2rbIcon {
        min-width: 50px;
        height: 50px;
        font-size: 2.4rem;
    }

    .s2rBox {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .section2Left img {
        min-height: auto;
        max-height: auto;
        width: 100%;
    }

    .s2rbtHeading {
        font-size: 1.6rem;
    }

    .s2rbtPara {
        font-size: 1.2rem;
    }
}