.imageDropdown{
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
}
.idRow{
    display: flex;
}

.idrBox{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    width: 230px;
    background: #fff;
    position: relative;
    overflow: hidden;
}

.idrBoxWrap{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #ffffff00;
    cursor: not-allowed;
}

.idrBox:hover{
    background: #eee;
}

.idrBox img{
    width: 60px;
    height: 60px;
}

.idrbtHeading{
    font-weight: 500;
    font-size: 1.32rem;
    display: flex;
    gap: 6px;
    align-items: center;
}

.idrbtHeading span{
    font-size: 1rem;
    padding: .32rem .6rem;
    background: rgba(255, 199, 46, 0.37);
    color: goldenrod;
    border-radius: 100px;
}

.idrbtPara{
    font-weight: 400;
    line-height: 1.32rem;
    font-size: 1rem;
}

.idNew{
    background: linear-gradient(to right, dodgerblue, #1f00eb);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    font-size: 1.2rem;
    color: #fff;
}

.idnHead{
    display: flex;
    align-items: center;
    gap: 1rem;
}