.card {
  margin: 10px;
  width: 350px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.card .content {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card .contentTop {
  display: flex;
  align-items: center;
  gap: 10px;
}

.isLoading .image,
.isLoading h2,
.isLoading p,
.isLoading h3,
.isLoading .img {
  background: #ddd;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.isLoading .image {
  height: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.isLoading .img {
  min-width: 50px;
  height: 50px;
}

.isLoading h2 {
  height: 30px;
  width: 100%;
}

.isLoading h3 {
  height: 20px;
}

.isLoading p {
  height: 70px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}