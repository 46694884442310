.ZappAcademyFull {
    scroll-behavior: smooth;
    width: 100%;
    max-width: 1440px;
    margin: auto;
}

.zappAcademyHeroWrap {
    background-image: url("../../../public/img/zabg.png");
    background-position: center;
    background-size: cover;
}

.zappAcadHero {
    padding: 4rem 2rem;
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 2rem;
}

.heading {
    font-size: 4rem;
    font-weight: 700;
}

.para {
    font-size: 1.8rem;
    color: #777;
}

.subHeading {
    font-size: 2.8rem;
    font-weight: 700;
}

.title {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #056ffa;
    font-weight: 600;
}

.heroBtn {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.6rem;
}

a.heroBtnPrimary {
    background: #056ffa;
    padding: .8rem 2rem;
    border: 2px solid #056ffa;
    border-radius: 100px;
    color: #fff;
    text-decoration: none;
}

a.heroBtnSecondary {
    padding: .8rem 2rem;
    border: 2px solid #056ffa;
    border-radius: 100px;
    text-decoration: none;
    color: #056ffa;
}

.zappAcadImg {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.zappAcadImg img {
    width: 160px;
    height: 270px;
    border-radius: 200px;
}

.zappAcadImg img:nth-child(2),
.zappAcadImg img:nth-child(3) {
    margin-top: 6rem;
}

.zappAcadEdu {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.zappAcadEduService {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
}

.zappAcademyMain {
    padding: 0 0rem;
}

.zamSearch {
    position: sticky;
    top: 70px;
    padding: 1.5rem 2rem;
    background: #fff;
    z-index: 2;
}

.zamSearch form {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
}

.zamSearch form input {
    padding: 1rem 2rem;
    background: #eee;
    border: none;
    outline: none;
    font-family: 'Poppins', sans-serif;
    border-radius: 100px 0 0 100px;
}

.zamSearch form button {
    border: none;
    outline: none;
    background: #056ffa;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    padding: 1rem 2rem;
    border-radius: 0 100px 100px 0;
}

.zamCat {
    padding: 1.2rem 2rem 0;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    display: none;
}

.zcLeft {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.6rem;
}

.zcRight {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 1.4rem;
    overflow-x: auto;
    color: #777;
}

.zcLink {
    cursor: not-allowed;
}

.zamWrap {
    display: flex;
    align-items: start;
    font-size: 1.6rem;
    padding: 0 4rem;
}

.zamLeft {
    position: sticky;
    top: 180px;
    max-width: 280px;
    min-width: 280px;
    height: calc(100vh - 180px);
    padding: 2rem 0;
    z-index: 1;

}

.zamlHeading {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.zamlText ul {
    list-style: none;
    margin: 1rem 0;
    margin-left: 2rem;
    color: #777;
}

.zamlLink {
    margin: .5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: not-allowed;
}

.activeLink {
    color: #000;
    font-weight: 700;
    cursor: pointer;
}

.soon {
    background: #eee;
    color: #777;
    height: 20px;
    padding: 0 1rem;
    border-radius: 20px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zamRight {
    width: calc(100% - 280px);
    padding: 1.4rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
}

@media (max-width: 768px) {
    .zappAcadHero {
        width: 80%;
    }

    .zappAcadImg {
        display: none;
    }

    .zappAcadEdu {
        padding: 4rem 2rem;
    }

    .zamSearch {
        top: 60px;
        padding: 1rem;
    }

    .zamLeft {
        max-width: 250px;
        min-width: 250px;
    }

    .zamRight {
        width: calc(100% - 250px);
        gap: 2rem;
    }
}

@media (max-width: 600px) {

    .zappAcadHero {
        width: 100%;
    }

    .zappAcademyHeroWrap {
        background-image: none;
    }

    .heading {
        font-size: 3rem;
    }

    .para {
        font-size: 1.4rem;
    }

    .subHeading {
        font-size: 2.2rem;
    }

    .zappAcadEduService {
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;
    }

    .zamCat {
        display: flex;
    }

    .zamLeft {
        display: none;
    }

    .zamRight {
        width: 100%;
        justify-content: center;
    }

    .zamSearch form {
        max-width: 100%;
    }
}