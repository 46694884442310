.pcCard{
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 1rem 2rem;
    border-radius: 6px;
    width: 300px;
}
.pcCardWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.pcCardSector{
    padding: 1rem 0;
    overflow-x: scroll;
}

.pcCardSector::-webkit-scrollbar{
    height: 0;
    width: 0;
}

.pccStatus{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.pccExtra{
    display: flex;
    gap: .75rem;
    align-items: center;
}

.pccLogo{
    width: 60px;
    height: 60px;
}

.pccLogo img{
    width: 100%;
    height: 100%;
}

.pcceValue{
    font-size: 1.4rem;
    font-weight: 600;
}

.pcceTitle{
    font-size: 1.32rem;
    width: 100px;
}

.pcceGain{
    color: rgb(1, 197, 102);
}

.pcceLoss{
    color: rgb(255, 36, 91);
}

.pcceGainBottom{
    display: flex;
    align-items: center;
    gap: .75rem;
}