/* document CSS */
.noDataFoundImage{
    width: 300px;
    object-fit: contain;
}

.documentContainer{
    margin-top: 4rem;
}
.documentContainer>h1{
    font-size: 2.4rem;
    font-weight: 500;
    color: #303030;

}
.documentBox{
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1rem;
}
.documentBoxSpan{
    padding-left: 2rem;
    padding-right: 4rem;
    border-right: 1px solid #E0E0E0;
}
.documentBoxSpan:last-child{
    border-right: 0px;
}
.documentBoxSpan>h5{
    text-transform: uppercase;
    color: #9E9E9E;
    font-weight: 600;
    font-size: 1.4rem;

}
.documentBoxSpan>h3{
    color: #303030;
    font-weight: 500;
    font-size: 1.6rem;
    margin-top: 0.5rem;
}

.documentBoxInvestor{
    margin-top: 4rem;
}
.documentBoxInvestor>h2{
    margin-top: 2rem;
    font-size: 1.8rem;
    color: #303030;
    font-weight: 600;
}
.documentBoxInvestor>div{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 2rem;
}



/* DOCUMENT CARD */

.documentCardContainer{
    border: 1px solid #E0E0E0;
    border-bottom: 3px solid #E0E0E0;
    border-radius: 5px;
    padding: 1rem 2rem;
    min-width: 200px;
    width: 48%;
    background-color: rgb(252, 243, 240);
    cursor: pointer;

}
.documentCardImageNameContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.documentCardImageNameContainer>img{
    width: 7rem;
    height: 7rem;
    
}
.documentCardImageNameContainer>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2rem;
}
.documentCardImageNameContainer>div>h2{
    font-size: 1.6rem;
    font-weight: 500;
    color: #303030;
    text-transform: capitalize;
}



@media screen and (max-width : 768px) {
    .documentBox{
        display: flex;
        flex-direction: column;
    }
    .documentBoxSpan{
        padding-top: 1rem;
        padding-left: 2rem;
        padding-right: 0rem;
        padding-bottom: 2rem;
        border-right: 0px;
        border-bottom: 1px solid #E0E0E0;
    }
    .documentBoxSpan:last-child{
        border-bottom: 0px;

    }
}
/* @media screen and (max-width : 425px) {
} */


