.modalBoxWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #c0bebe1d;
    backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;
}

.modalBox {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    font-size: 1.4rem;
    width: 300px;
    background: #fff;
    border-radius: 4px;
    position: relative;
}

.closeModal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2.8rem;
    cursor: pointer;
}

.modalBoxTop {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    font-size: 5rem;
    color: #fff;
}

.modalBoxTop img {
    height: 100%;
    object-fit: cover;
}

.errorBg {
    background: rgb(255, 56, 38);
}

.errorColor {
    color: rgb(255, 56, 38);
}

.errorBorder {
    border-top: 4px solid rgb(255, 56, 38);
}

.successBg {
    background: rgb(1, 150, 78);
}

.successColor {
    color: rgb(1, 150, 78);
}

.successBorder {
    border-top: 4px solid rgb(1, 150, 78);
}

.infoBg {
    background: goldenrod;
}

.infoColor {
    color: goldenrod;
}

.infoBorder {
    border-top: 4px solid goldenrod;
}

.modalBoxBottom {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mbbText {
    font-size: 2.2rem;
    font-weight: 500;
    letter-spacing: .2rem;
}

.mbbBtn {
    padding: .8rem 1.4rem;
    border-radius: 6px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
}
