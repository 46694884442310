  .accordion {
    width: 100%;
    /* background-color: ; */
    overflow: hidden;
  }
  
  .accordionItem {
    margin-top: 1rem;
    /* border-bottom: 1px solid #e0e0e0; */
  }
  
  .accordionItem:last-child {
    border-bottom: none;
  }

  .accordionTitle{

  }
  
  .accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #303030;
    padding: 1rem 1.5rem;
    font-size: 1.6em;
    cursor: pointer;
    width: 100%;
    text-align: left;
    background-color: #e0e0e0;
    border: 0.5px solid #E0E0E0;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .accordionHeader:focus {
    outline: none;
  }
  
  .accordionIcon {
    font-size: 1.5em;
  }
  
  .accordionContent {
    display: none;
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
    /* background-color: #f9f9f9; */
    border: 1px solid #e0e0e0;
  }
  
  .accordionContent.open {
    display: block;
  }
  
  .accordionContent p {
    margin: 0;
  }
  