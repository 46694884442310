.body {
    width: 100%;
    max-width: 1440px;
    margin: 0rem auto;
}
.noDataFoundImage{
    width: 300px;
    object-fit: contain;
}
.container {
    /* padding-top: 50px; */
    margin: 4rem 12rem;
}

.backButtonContainer {
    /* margin: 0 16rem; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.section1 {
    /* margin: 0 16rem; */
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.startupNameAndDescriptionContainer {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
    
}

.shareAndWatchlistButtonContainer{
    width: 20%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-left: auto;
}

.startupLogo {
    /* height: 100px; */
    width: 70px;
    object-fit: contain;
    border-radius: 5px;

}

.startupName {
    font-size: 24px;
    font-weight: 600;
}

.startupDesc {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #616161;
}

.tags {
    display: flex;
    row-gap: .5rem;
    flex-wrap: wrap;
    margin-top: 25px;
}



/* Outlets and Right side about container - Section CSS */

.section2{
    margin-top: 4rem;

}
.section2OptionSliderContainer{
    width: 100%;
    border-bottom: 0.5px solid #9E9E9E;
    display: flex;
    flex-direction: row;
}
.optionSlider{
    padding: 1rem 2rem 1rem 1rem;
    font-size: 1.8rem;
    font-weight: 500;
    color:#616161;
    cursor: pointer;
}
.optionSlider:hover{
    font-weight: 500;
    color:#056ffa;
    border-bottom: 2px solid #056ffa;
}
.optionSliderActive{
    font-weight: 500;
    border-bottom: 2px solid #616161;

}

.section2Container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.section2LeftContainer{
    width: 65%;

}
.section2RightContainer{
    width: 27%;

}

.section2RightContainerBox{
    margin-top: 5rem;
    border: 1px solid #E0E0E0;
    border-bottom: 3px solid #E0E0E0;
    border-radius: 4px;
    padding: 1rem;

}
.section2RightContainerBox>h4{
    font-size:1.4rem;
    color: #9E9E9E;
    text-transform: uppercase;
    font-weight: 600;
}

.section2RightSmallBox{
    margin-top: 1.5rem;

}
.section2RightSmallBox>h4{
    font-size: 1.4rem;
    color: #616161;
    font-weight: 600;
    /* margin-bottom: 0.5rem; */
    
}
.section2RightSmallBox>h5{
    font-size: 1.4rem;
    color: #616161;
    font-weight: 400;
    /* margin-bottom: 0.5rem; */
    
}
.section2RightSmallBox>a{
    font-size: 1.2rem;
    color: #056ffa;
    font-weight: 500;
    
}
.section2RightSmallBox>div>a{
    font-size: 1.8rem;
    color: #616161;
    margin-right: 0.5rem; 
}
.section2RightSmallBox>div>a:hover{
    color: #9E9E9E;
}




.section5 {
    /* margin: 0 16rem; */
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.section5Left {
    width: 50%;
}

.comDetail {
    /* margin: 0 16rem; */
}


.section5Right {
    width: 35%;
    position: relative;
    padding: 0rem 0rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section5RightButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.section5RightBody {
    margin: 0px;
}

.comDivision {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.comdHeading {
    color: #777;
    font-size: 1.6rem;
    font-weight: 600;
}

.comdMember {
    display: flex;
    gap: .5rem;
    flex-direction: column;
    position: relative;
}

.commWrap {
    height: 50px;
    padding-left: 1rem;
}

.commWrap img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
}

.commWrap img.img1 {
    left: 0;
}

.commWrap img.img2 {
    left: 32px;
}

.commWrap img.img3 {
    left: 70px;
}

.commWrap .img4 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    left: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #056ffa;
    color: #fff;
    font-weight: 700;
    font-size: 1.6rem;
}

.comdStage {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.comsWrap {
    padding-left: 1rem;
}

.comsPara {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 500;
    font-size: 1.4rem;
    /* margin-top: rem; */
}

/* .section5RightBody div {
    padding-top: 1.5rem;
    display: flex;
    justify-content: space-between;

} */

.section5RightBodyHeading {
    font-size: 1.8rem;
}

.section5RightNumbers {
    margin: auto;
    position: absolute;
    bottom: 15px;
    left: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.section5RightNumbers span {
    color: #D0CFCF;
    font-size: 12px;
    margin-left: 5px;
}

.section5RightHeading {
    font-size: 2rem;
}



/* media queries */
@media screen and (max-width : 1024px) {
    .container{
        margin: 4rem 6rem;
    }
    .section2Container{
        display: flex;
        flex-direction:column-reverse;
    }
    
    .section2LeftContainer{
        width: 100%;
        margin-top: 4rem;
    
    }
    .section2RightContainer{
        width: 100%;
    
    }
    .section2RightContainerBox{
        margin-top: 2rem;
    }
}

@media screen and (max-width : 768px) {
    .container{
        margin: 4rem 4rem;
    }
    .section1 {
        flex-direction: column;
    }
    .startupNameAndDescriptionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    
    .shareAndWatchlistButtonContainer{
        display: flex;
        flex-direction: row;
        margin-left: 0;
        margin-top: 4rem;
        width: 100%;
    }
    .section2OptionSliderContainer{
        overflow: scroll;
    }
}
@media screen and (max-width : 425px) {
    .container{
        margin: 4rem 2rem;
    }
}