.container {
    width: 100%;
    padding: 2rem;

}

.heading {
    font-size: 2.4rem;
}

.subHeading {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
}

.startupDashOffer {
    margin: 2rem 0;
    padding: 1rem;
    background: #F5f5f5;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 10px;
    font-size: 1.32rem;
}

.startupDashHero{
    margin: 2rem 0;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    height: 200px;
}

.startupDashHero .bgsvg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.startupDashHeroWrap{
    padding: 2rem;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sdhwLeft{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.sdhwlImg{
    width: 120px;
    height: 120px;
    border: 4px solid #fff;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
}

.sdhwlImg img{
    width: 80%;
    border-radius: 50%;
}

.sdhwlText{
    margin-top: 4rem;
}

.sdhwltHead{
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2rem;
}

.sdhwltDesc{
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 500;
    color: #3d3d3d;
}

.sdhwltSocial{
    margin: .5rem 0;
    font-size: 2rem;
    display: flex;
    align-items: center;
    gap: .4rem;
    color: #777;
}

.sdhwltSocial a{
    color: #777;
    text-decoration: none;
}

.startupDashStatus {
    margin: 2rem 0;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.startupDashStatusWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.startupDashDetails{
    display: flex;
    margin: 2rem 0;
    gap: 2rem;
}

.sddLeft,
.sddRight{
    width: 50%;
    padding: 2rem;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.sddTop{
    display: flex;
    gap: 1rem;
}

.sddIcon{
    width: 60px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #222;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .7rem;
}

.sddIcon img{
    width: 100%;
    height: 100%;
}

.sddStatusLV{
    padding: .4rem 1rem;
    border: 1px solid rgb(1, 218, 102);
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    gap: .5rem;
}

.sddStatusLV div{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgb(1, 218, 102);
}

.sddStatusNA{
    padding: .4rem 1rem;
    border: 1px solid rgb(226, 54, 35);
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    gap: .5rem;
}

.sddStatusNA div{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgb(226, 54, 35);
}

.sddPara{
    font-size: 1.32rem;
    margin-top: 1.5rem;
}

.startupDashInsight {
    margin: 2rem 0;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.startupDashInsightWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.sdiCard {
    display: flex;
    gap: 2rem;
    align-items: center;
    padding: 1rem;
    font-size: 2rem;
}

.sdicIcon {
    font-size: 2.8rem;
    background: #F4F9FF;
    color: #056ffa;
    height: 60px;
    width: 60px;
    line-height: 65px;
    text-align: center;
    border-radius: 10px;
}

.sdicHeading {
    font-size: 1.2rem;
    font-weight: 600;
}

.sdicPara {
    font-size: 1.6rem;
    font-weight: 800;
}

.startupDashSector {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.sdsCard {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}

@media (max-width:500px) {
    .startupDashDetails{
        flex-direction: column;
    }

    .sddLeft, 
    .sddRight{
        width: 100%;
        text-align: justify;
    }
}