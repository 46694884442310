.container{
    display: flex;
    justify-content: space-between;
}
.leftContainer{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}
.mainHeading{
    font-size: 3.2rem;
    line-height: 3.4rem;
}
.titleHeading{
    font-size: 2rem;
    font-weight: 600;
}
.descriptionText{
    font-size: 1.4rem;
    font-weight: 300;
    padding-top: 0.5rem;
    transform: translateY(-100%);
    transition: transform 1s ease-in-out;


}

.steps{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 4rem;
    width: 100%;


}
.step{
    border: 1px solid #e9e9e9;    
    border-radius: 10px;
    padding: 2rem;
    cursor: pointer;

}


/* Right container */
.rightContainer{
    display: flex;
    justify-content: right;
    align-items: center;
    width: 60%;

}
.stepPhoto{
    width: 90%;
}

.display{
    display: none;

}

.active{
    transform: translateY(0%);
    transition: transform 1s ease-in-out;

}



@media screen and (max-width:768px) {
    .container{
        flex-direction: column;
    }
    .leftContainer{
        width: 100%;
    }
    .rightContainer{
        width: 100%;
    }
    .stepPhoto{
        width:100%;
    }
    .rightContainer{
        margin-top: 4rem;
    }
}
@media screen and (max-width:600px) {
    .step{
        padding: 1.5rem;
    }
    .titleHeading{
        font-size: 1.6rem;
    }
    .descriptionText{
        font-size: 1.4rem;    
    
    }
    
}