
.container{
    margin: 4rem 4rem
}

.heading {
    font-size: 3.2rem;
    font-weight: 800;
    line-height: 3.5rem;
}

.heading2{
    width: 40rem;
    margin-top: 2rem;
    font-size: 1.4rem;
}

.sectionStartupGrid{
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.noFavourite{
    width: 60%;
    margin: 0 auto;
}

.noFavourite img{
    width: 100%;
}

@media (max-width: 480px) {
    .container {
        margin: 2rem 2rem;
    }

    .heading {
        font-size: 2.8rem;
        line-height: 120%;
    }

    .heading2{
        width: 100%;
        font-size: 1.4rem;
    }
}