.FAQ{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 10rem auto 0rem;
}
.FAQ>h1{
    margin-bottom: 5rem;
    text-align: center;
    font-size: 4.5rem;
    opacity: 0.5;
}
.FAQ-container{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    width: 100%;
    margin: auto;
}
.FAQ-box{
    width: 45%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}
.FAQ-links{
    text-decoration: none;
    color: #056ffa;
    opacity: 0.7;
    font-weight: 400;
    cursor: pointer;
}
.FAQ-links:hover{
    opacity: 1;
}


@media screen and (max-width : 750px){
    .FAQ-container{
        display: flex;
        flex-direction: column;
    }
    .FAQ-box{
        width: 100%;
        /* justify-content: center; */
    }

}