.hoverButtonContainer {
    position: relative;
    display: inline-block;
  }
  
  .hoverButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .icon {
    font-size: 24px;
    color: #5f5f5f;
  }
  
  .infoBox {
    position: absolute;
    top: calc(100% + 5px);
    /* left: 50%; */
    /* transform: translateX(-50%); */
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    min-width: 180px;
  }
  