.mainHeading {
    font-size: 3.2rem;
    font-weight: 600;
}

.titleHeading {
    font-size: 1.8rem;
    font-weight: 800;
    color: #000;
    margin-bottom: 3rem;

}

.noInvestmentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    width: 50%;
    margin: 2rem auto 0;
}

img.noInvestment {
    width: 100%;
}

.container {
    margin: 4rem 4rem
}

.portfolioWelcome {
    margin: 2rem 0;
}

.pwWelcome {
    font-size: 1.5rem;
}

.pwName {
    font-size: 2.2rem;
    font-weight: 700;
}

.portfolioTotal {
    background: #f5f5f5;
    margin: 2rem 0;
    padding: 2rem;
    border-radius: 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.portfolioCompany {
    padding: 1rem 0;
}

.portfolioHeading {
    font-size: 2.2rem;
    font-weight: 700;
}

.portfolioCompanyWrap {
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 2rem 0;
}

@media (max-width: 480px) {
    .mainHeading {
        font-size: 2.8rem;
    }

    .titleHeading {
        font-size: 1.6rem;
    }

    .container {
        margin: 2rem 2rem;
    }

    .noInvestmentDiv{
        width: 100%;
    }
}