.discoverSidebarContainer {
    position: fixed;
    top: calc(50% - 25vh);
    left: 2rem;
    z-index: 10;
    border: 1px solid #E0E0E0;
    background-color: #f8f8f8;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.discoverSlidebarHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    border-bottom: 1px solid #E0E0E0;
    font-size: 1.4rem;


}

.discoverSlidebarContentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    width: 100%;


}

.discoverSlidebarContentBox:hover {
    background-color: #E0E0E0;

}

.discoverSlidesIcons {
    font-size: 3rem;
    display: flex;
}

.discoverSlidebarContentBox>h5 {
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 0.5rem;
}

.active {
    color: #056ffa;
    font-weight: 500;
    background-color: #F4F9FF;

}


@media (max-width:768px) {
    .discoverSidebarContainer {
        flex-direction: row;
        position: fixed;
        /* bottom: 2rem; */
        left: calc(50% - 17rem);
        top: calc(100% - 8rem);

    }

    .discoverSlidebarHeader {
        padding: 1rem 1rem;
        font-size: 1.2rem;
        border-bottom: none;

    }

    .discoverSlidebarContentBox {
        padding: 1rem 1rem;
        font-size: 1.2rem;
    }

    .discoverSlidesIcons {
        font-size: 2rem;
    }
}