.dealCardContainer{
    border: 1px solid #E0E0E0;
    border-bottom: 3px solid #E0E0E0;
    border-radius: 5px;
    min-width: 250px;
    width: 350px;
    cursor: pointer;
    height: 430px;
}
.dealBottomCardContainer{
    padding: 1.5rem;
    border-top: 1px solid #E0E0E0;
}
.dealCardContainer:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;;
}
.dealImageContainer{
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dealImageContainer>img{
    object-fit:cover;
    height: 180px;
    width: 100%;
}
.dealNameLogoBox{
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
}
.dealNameLogoBox>img{
    width: 5rem;
    /* height: 5rem; */
    border-radius: 5px;
}
.dealNameAndLocation{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.dealNameAndLocation>h2{
    font-size: 1.6rem;
    color: #303030;
    font-weight: 600;

}
.dealNameAndLocation>h3{
    font-size: 1.2rem;
    color: #9E9E9E;
    font-weight: 500;
}

.dealCardDescription{
    display: inline-block;
    margin-top: 2rem;
    font-size: 1.4rem;
    color: #303030;
    font-weight: 400;

}
.dealCardFocusSectors{
    margin-top: 1rem;
    
}
.dealCardFocusSectors>h3{
    font-size: 1.2rem;
    font-weight: 500;
    color: #616161;
    
}
.dealCardFocusSectors>div{
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;

}
.dealCardInvestmentAndIncubatee{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.dealCardInvestmentAndIncubatee>div{
    display: flex;
    flex-direction: column;
}
.dealCardInvestmentAndIncubatee>div>h3{
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #9E9E9E;
    font-weight: 500;
}
.dealCardInvestmentAndIncubatee>div>h2{
    font-size: 1.4rem;
    color: #616161;
    font-weight: 400;
}