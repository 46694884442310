.common-navbar {
  max-width: 1440px;
  margin: auto;
  background-color: transparent;
  height: 8rem;
  /* border-bottom: 1px solid #eee; */
}

.common-navbar-container {
  width: 90%;
  margin: auto;
  height: 100%;
  display: flex;
  gap: 2rem;

}

.common-navbar-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
}
/* adding the home route to the zapp invest logo */
.common-navbar-left a {
  height: 100%;
}
.common-navbar-left a img {
  height: 50%;
  margin-top: 25%;
}
.common-navbar-left .hamburger-logo {
  display: none;
}

.common-navbar-right {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.common-navbar-menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  gap: 1.5rem;
}
#slideBar {
  transform: translate(0%);
}
.common-navbar-menu li {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight:500;
  color: black;
  cursor: pointer;
  position: relative;
}
.common-navbar-menu li a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: black;
  font-size: inherit;
  font-weight: inherit;

}

.homenavbarcolor li a {
  color: white;
}
.homenavbarcolor li {
  color: white;
}

.common-navbar-menu li:hover {
  color: #030c22;
}

.common-navbar-menu li a:hover {
  color: #030c22;
}

.homenavbarcolor li a:hover {
  color: white;
}
.homenavbarcolor li:hover {
  color: white;
}

.common-navbar-button {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.common-navbar-button li {
  font-size: 2rem;
  list-style: none;
  color: #056ffa;
  cursor: pointer;
}
.common-navbar-button li a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #056ffa;
}

.homenavbarbtncolor li a {
  color: white;
}
.homenavbarbtncolor li {
  color: white;
}
.common-navbar-button li:hover {
  color: #030c22;
}
.homenavbarbtncolor li a:hover {
  color: white;
}
.homenavbarbtncolor li:hover {
  color: white;
}
#navbar-signup {
  border-radius: 10px;
  padding: 10px;
}

.investorDropdownHover{
  transition: .5s;
}

.investorDropdown{
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  transition: .5s;
  
}

.investorDropdownHover:hover .investorDropdown{
  display: initial;
  transition: .5s;

}

@media screen and (max-width: 750px) {
  .common-navbar {
    position: relative;
  }
  .common-navbar-left {
    width: 100%;
    justify-content: space-between;
  }
  .common-navbar-left .hamburger-logo {
    display: block;
  }

  .common-navbar-right {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    /* justify-content:space-evenly; */
    justify-content: center;
    margin-top: 8rem;
    position: absolute;
    height: 100vh;
    max-width: 350px;
    left: 0px;
    gap: 100px;
    background-color: white;
    transition: transform 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    -moz-box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .common-navbar-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .common-navbar-menu li {
    font-size: 4rem;
    color: #056ffa;
  }
  .common-navbar-menu li a {
    font-size: 4rem;
    color: #056ffa;
  }
  .common-navbar-button li {
    font-size: 4rem;
    color: #056ffa;
  }

  .common-navbar-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  #navbar-signup {
    border-radius: 10px;
    border: solid 1px #056ffa;
    padding: 10px;
  }
}
