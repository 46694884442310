.blogIndexFull {
  scroll-behavior: smooth;
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

.blogIndexHero {
  padding: 4rem 2rem;
  width: 54%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;
}

.heading {
  font-size: 4rem;
  font-weight: 700;
}

.para {
  font-size: 1.8rem;
  color: #777;
}

.heroBtn {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.6rem;
}

a.heroBtnPrimary {
  background: #056ffa;
  padding: .8rem 2rem;
  border: 2px solid #056ffa;
  border-radius: 100px;
  color: #fff;
  text-decoration: none;
}

.heroBtnSecondary {
  padding: .8rem 2rem;
  border: 2px solid #056ffa;
  border-radius: 100px;
  text-decoration: none;
  color: #056ffa;
  transition: .4s;
}

.heroBtnSecondary:hover {
  background: #056ffa;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 700px) {
  .blogIndexHero {
    width: 100%;
  }

  .blogIndexHeroWrap {
    background-image: none;
  }

  .heading {
    font-size: 3rem;
  }

  .para {
    font-size: 1.4rem;
  }
}

/* .addBlog-button {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #1e90ff;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 15px;
  transition: 0.3s;
}

.addBlog-button:hover {
  background-color: #1e90ff;
  opacity: 0.8;
}

.addBlog-button:active {
  background-color: #1e90ff;
  opacity: 0.8;
}

.addBlog-button:focus {
  background-color: #1e90ff;
  opacity: 0.8;
} */