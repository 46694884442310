.modalBoxWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #c0bebe1d;
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;
}

.modalBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 4rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.modalForm {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-direction: column;
    position: relative;
}

.startupDocBtn{
    padding: 1rem 2rem;
    border-radius: 10px;
    background: #056ffa;
    color: #fff;
    font-size: 1.4rem;
    text-align: center;
    gap: 1rem;
    cursor: pointer;
}

.closeModal{
    position: absolute;
    top: -2rem;
    right: -2rem;
    font-size: 2.8rem;
    cursor: pointer;
}

.fileField{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.fileField .styleFile{
    position: absolute;
    top: 3.5rem;
    background: #fff;
    width: 98%;
    left: 1%;
    overflow: hidden;
    height: 30px;
    line-height: 30px;
    font-size: 1.4rem;
    font-weight: 400;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
}

.fileField label{
    font-size: 1.6rem;
    font-weight: 500;
}

.fileField input{
    border: 1px solid #eee;
    padding: 1rem;
    border-radius: 4px;
}

@media (max-width: 600px) {
    .modalBox {
        width: 80%;
        padding: 2rem;
    }

    .modalForm{
        width: 90%;
    }
}