.selectButton {
    padding: 1rem 2rem;
    background-color: white;
    color: black;
    border: 0.5px solid black;
    border-radius: 5px;
    font-size: inherit;
    cursor: pointer;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 1s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* min-width: 150px; */
}

.selectButton:hover {
    background-color: #f0f0f0;
}

.selectbox {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    min-width: 15rem;
    max-height: 20rem;
    overflow: scroll;
    font-size: 1.4rem;
    z-index: 1;
}

.selectboxOption {
    padding: 0.4rem 0.5rem;
    cursor: pointer;
    font-size: inherit;
    transition: background-color 1s ease;
    border-radius: 5px;
    
}

.selectboxOption:hover {
    background-color: #f1f1f1;
}