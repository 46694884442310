.mainHeading {
    font-size: 3.2rem;
    font-weight: 600;
}

.titleHeading {
    font-size: 1.8rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 3rem;

}

.mainHeading {
    font-size: 3.2rem;
    font-weight: 600;
}

.container {
    margin: 4rem 4rem;
}

.noTransaction {
    margin: 0 auto;
    width: 50%;
}

.noTransaction img {
    width: 100%;
}

.horizontalLine {
    background-color: #f5f5f5;
    height: 1px;
    margin: 1rem 0rem;
}

.dataSection {
    margin-top: 4rem;
    background-color: #056ffa;
    border-radius: 10px;
    padding: 4rem 4rem 8rem;
    position: relative;
}

.dataSection h2 {
    font-size: 2.4rem;
    color: #fff;
    display: flex;
    align-items: center;

}

.dataSection p {
    margin-top: 2rem;
    font-size: 1.8rem;
    color: #fff;

}

.dataSection h1 {
    margin-top: 0.5rem;
    font-size: 3.2rem;
    color: #fff;

}

.dataSectionBox {
    display: flex;
    position: absolute;
    left: 50%;
    top: calc(100% - 50px);
    transform: translateX(-50%);
    padding: 2rem 2rem;
    min-width: 300px;
    /* height: 100px; */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

}

.verticalLine {
    background-color: #f5f5f5;
    width: 1px;
}

.box {
    /* flex: 1; */
    padding: 0px 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box h1 {
    color: #000;
    margin: 0px;
    font-size: 2.4rem;
}

.box p {
    margin-top: 1rem;
    color: #000;
    font-size: 1.2rem;

}

.statusSection {
    margin-top: 10rem;
    padding: 2rem 0rem;
}

.statusboxContainer {
    display: flex;
    justify-content: space-between;
}

.statusBox {
    width: 200px;
    position: relative;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    overflow: hidden;
    border-radius: 12px;
}

.statusBoxWrap {
    position: absolute;
    top: 174px;
    left: 0;
    width: 100%;
    height: 100%;
}

.sbTop {
    padding: .6rem 1.2rem;
    font-size: 1.2rem;
    border-radius: 200px;
    display: flex;
    align-items: center;
    gap: .4rem;
}

.gColor {
    border: 1px solid mediumseagreen;
    background: rgba(102, 205, 171, 0.377);
}

.gBg {
    background: mediumseagreen;
}

.yColor {
    border: 1px solid rgb(255, 200, 59);
    background: rgba(255, 230, 0, 0.377);
}

.yBg {
    background: rgb(255, 200, 59);
}

.rColor {
    border: 1px solid rgb(255, 51, 68);
    background: rgba(255, 51, 68, 0.377);
}

.rBg {
    background: rgb(255, 51, 68);
}

.bColor {
    border: 1px solid dodgerblue;
    background: rgba(0, 119, 255, 0.377);
}

.bBg {
    background: dodgerblue;
}

.sbMiddle {
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.sbmLeft {
    font-size: 3.6rem;
    font-weight: 700;
}

.sbmRight {
    display: flex;
    flex-direction: column;
}

.sbBottom {
    border: 1px solid #000;
    padding: .6rem 1.4rem;
    font-size: 1.2rem;
    border-radius: 200px;
    cursor: pointer;
}

@media (max-width: 1150px) {
    .box h1 {
        color: #000;
        margin: 0px;
        font-size: 1.8rem;
    }

    .box p {
        margin-top: 1rem;
        color: #000;
        font-size: 1rem;

    }

    .statusboxContainer {
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }

    .statusboxContainerWrap {
        width: 100%;
    }
}


/* .statusbox h2{
    font-size: 1.8rem;
} */
/* .statusbox h3{
    
} */

.transactionSection {
    margin-top: 4rem;
    padding: 2rem 4rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
    /* height: 100px; */
}

.transactionTable {
    margin-top: 2rem;
    max-width: 100%;
}

.transactionTable table {
    width: 100%;
    font-size: 1.4rem;
    overflow-x: scroll;

}

.transactionTable th,
.transactionTable td {
    padding: .6rem 1rem;
}

.tableHeading {
    text-align: left;
}

.tdStatus {
    display: flex;
    align-items: center;
    gap: .8rem;
}

.tdsp {
    height: 10px;
    width: 10px;
    background: yellow;
    border-radius: 50%;
}

.tdsa {
    height: 10px;
    width: 10px;
    background: mediumseagreen;
    border-radius: 50%;
}

.tdsr {
    height: 10px;
    width: 10px;
    background: red;
    border-radius: 50%;
}

/* .tableRow{

} */

@media (max-width: 480px) {
    .mainHeading {
        font-size: 2.8rem;
    }

    .titleHeading {
        font-size: 1.6rem;
    }

    .container {
        margin: 2rem 2rem;
    }

    .dataSection {
        margin-top: 2rem;
        padding: 4rem 2rem 6rem;
    }

    .dataSectionBox {
        padding: 1rem;
        min-width: 280px;

    }

    .box {
        padding: 0px 1rem;
    }

    .statusboxContainer {
        justify-content: center;
        align-items: center;
    }

    .statusBox {
        width: 100%;
    }

    .noTransaction {
        width: 100%;
    }
}