.startupPage{
    width: 100%;
    max-width: 1440px;
    margin: auto;
}

.sectionContainer{
    margin-top: 6rem;
    padding-left: 12rem;
    padding-right: 12rem;


}

.section1{
    width: 100%;
    display: flex;
}
.section1Left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.section1Left>h1{
    padding-top: 2rem;
    font-size: 4.2rem;
    line-height: 4.4rem;
    color:#000;
    font-weight: 900;

}
.section1Left>p{
    padding-top: 2rem;
    font-size: 1.8rem;
    line-height: 2rem;
    color:#000;
    font-weight: 200;


}
.section1Right{
    width: 50%;
    text-align: right;

}
.section1Right img{
    width: 90%;
}

.tractionSection{
    padding-left: 20rem;
    padding-right: 20rem;
    margin: 5rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 2rem;
}
.tractionBox{
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* Section - startup already join */
.startupJoined{
    padding: 6rem 12rem;
    background: #F4F9FF;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 2rem;
}

.startupJoinedWrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3rem;
}

.startupJoined>h1{
    font-size: 4rem;
    font-weight: 800;
    line-height: 4.2rem;
}

.startupJoined>p{
    font-size: 1.8rem;
}



/* Why join us */

.startupWhyJoin{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 4rem 0;
}

.startupWhyJoinWrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
}

.startupWhyJoin>h1{
    font-size: 4rem;
    font-weight: 800;
    line-height: 4.2rem;
}

.startupWhyJoin>p{
    font-size: 1.8rem;
    line-height: 2rem;
    color: #5f5f5f;
}



/* Section - Startup registration process */
.startupRegProcessSection{
    margin: 0;
    padding: 6rem 16rem;
}
.startupRegProcessSection>h1{
    font-size: 5rem;
    text-align: center;
}
.startupRegProcessSection>p{
    font-size: 1.8rem;
    width: 90%;
    text-align: center;
}

.startupRegProcessSection a{
    font-size: 1.8rem;
    width: 90%;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #056ffa;
}


/* Section - apply */
.startupApplyRefer{
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    padding: 4rem 12rem 8rem;
}

.sarLeft,
.sarRight{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.startupApplyRefer h1{
    font-size: 2.8rem;
}

.startupApplyRefer p{
    font-size: 1.8rem;
    line-height: 1.8rem;
}

.startupArBtn{
    background: #056ffa;
    width: 300px;
    padding: 1rem 0;
    font-size: 1.6rem;
    border-radius: 10px;
    text-align: center;
    font-weight: 500;
}



/* How to register your startup section */
.sectionHowToRegisterStartup{
    padding: 4rem 6rem;
    background: linear-gradient(149deg, rgba(3,12,34,1) 0%, rgba(4,57,131,1) 37%, rgba(5,111,250,1) 100%);

}
.sectionHowToRegisterStartup>img{
    width: 100%;
}





  /* Media queries */


  @media screen and (max-width:1100px) {
    .sectionContainer{
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .section1Left>h1{
        font-size: 3.6rem;
        line-height: 3.8rem;
    }
    .section1Left>p{
        font-size: 1.6rem;
    }
    .tractionSection{
        padding-left: 10rem;
        padding-right: 10rem;
    }
    .startupRegProcessSection{
        padding: 6rem 6rem;
    }
    .startupJoined{
        padding: 6rem 6rem;
    }
    .startupJoined>h1{
        font-size: 4rem;
        font-weight: 800;
        line-height: 4.2rem;
    }
    
    .startupJoined>p{
        font-size: 1.8rem;
    }
  }
  @media screen and (max-width:1000px) {
    .assetBox{
        width: 45%;
    }
  }


  /* Media queries for tab screen */
  @media screen and (max-width:768px) {
    .section1{
        flex-direction: column;
        gap: 2rem;
    }
    .section1Left{
        width: 100%;
        justify-content: center;
    }
    .section1Left h1{
        font-size: 4.2rem;
        line-height: 4.4rem;
    }
    .section1Left p{
        font-size: 1.8rem;
    }
    .section1Right{
        width: 100%;

    }
    .tractionSection{
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .startupRegProcessSection>h1{
        font-size: 4.2rem;
    }
    .startupRegProcessSection>p{
        font-size: 1.6rem;
        width: 100%;
    }
    .sectionHowToRegisterStartup{
        padding: 4rem 2rem;
        background: linear-gradient(149deg, rgba(3,12,34,1) 0%, rgba(4,57,131,1) 37%, rgba(5,111,250,1) 100%);
    
    }
  }



  @media screen and (max-width:600px) {
    .sectionContainer{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .section1Left>h1{
        font-size: 3.2rem;
        line-height: 3.4rem;
    }
    .section1Left>p{
        font-size: 1.6rem;
    }
    .section1Right{
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .tractionSection{
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .sectionStartupCardContainer h1{
        font-size: 3.6rem;
        line-height: 3.8rem;
    }
    .startupRegProcessSection{
        padding: 6rem 3rem;
    }
    .startupRegProcessSection>h1{
        font-size: 3.2rem;
    }
    .startupRegProcessSection>p{
        font-size: 1.4rem;
        width: 100%;
    }
    .startupWhyJoin>h1{
        font-size: 3.2rem;
        line-height: 3.4rem;
    }
    
    .startupWhyJoin>p{
        font-size: 1.4rem;
        line-height: 1.6rem;
    }
    .startupJoined{
        padding: 6rem 3rem;
        text-align: left;
    }
    .startupJoined>h1{
        font-size: 3.2rem;
        line-height: 3.4rem;
    }
    
    .startupJoined>p{
        font-size: 1.4rem;
    }
    
  }